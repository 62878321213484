import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthServiceAdmin } from "src/app/admin/auth.service";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'fc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule]
})
export class LoginComponent implements OnInit {
  message: string = ""
  username?: string
  password?: string

  constructor(private authService: AuthServiceAdmin, private router: Router) { }

  ngOnInit() { }

  validateForm(): void {
    let forms = document.getElementsByClassName('needs-validation');

    Array.prototype.filter.call(forms, (form) => {
      if (form.checkValidity() === false) {
        event?.preventDefault();
        event?.stopPropagation();
      } else {
        this.login()
      }

      form.classList.add('was-validated');
    });
  }

  private login(): void {
    this.authService.login(this.username ?? '', this.password ?? '').subscribe(() => {
      if (this.authService.userIsLoggedIn()) {
        this.message = ""
        this.router.navigate(['/admin/dashboard']);
      } else {
        this.message = "Wrong credentials. Please try again!"
        this.password = ''
      }
    });
  }
}
