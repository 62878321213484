import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { IBreadCrumb } from '../models/IBreadCrumb';
import { ISiteSettings } from '../models/ISiteSettings';
import { setBreadCrumbReducer } from '../reducers/BreadCrumb.reducer';
import { setSiteSettingsReducer } from '../reducers/SiteSettings.reducer';

export interface AppState {
  readonly breadCrumb: IBreadCrumb;
  readonly siteSettings: ISiteSettings;
}

export const reducers: ActionReducerMap<AppState> = {
  breadCrumb: setBreadCrumbReducer,
  siteSettings: setSiteSettingsReducer,
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
