import { Component, computed, DestroyRef, inject, Input } from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SharedService } from "../../services/shared.service";
import { CustomPopupService } from "src/app/services/custom-popup.service";
import { CustomPopup, CustomPopupKey } from "src/app/types/cusom-popup.type";

declare var $: any;

@Component({
  selector: "fc-custom-popup",
  templateUrl: "./custom-popup.component.html",
  styleUrls: ["./custom-popup.component.scss"],
  standalone: true,
  imports: [],
})
export class CustomPopupComponent {
  @Input({ required: true }) popupKey!: CustomPopupKey;

  private _customPopupService = inject(CustomPopupService);
  private _router = inject(Router);
  private _destroyRef = inject(DestroyRef);

  customPopup = computed(
    () => this._customPopupService.popups()[this.popupKey],
  );

  private _navigationUrl$ = this._router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((navigation) => navigation.url),
    filter((url) => !url.startsWith("/print") && !url.startsWith("/sellsheet")),
  );

  private _customPopup$ = toObservable(this.customPopup).pipe(
    filter((popup): popup is CustomPopup => !!popup),
  );

  constructor(public sharedService: SharedService) {}

  ngOnInit(): void {
    combineLatest([this._navigationUrl$, this._customPopup$])
      .pipe(
        filter(([url, customPopup]) => {
          if (
            !customPopup.properties.isActive ||
            url === customPopup.properties.actionUrl ||
            !customPopup.properties.targetUrls
          ) {
            return false;
          }

          const splittedTargetUrls =
            customPopup.properties.targetUrls.split(",");

          if (splittedTargetUrls.includes("/")) {
            if (url === "/") {
              return true;
            }
          } else {
            if (
              this.popupKey === "custom-popup-1" &&
              url !== "/admin/dashboard"
            ) {
              return true;
            } else {
              const result = splittedTargetUrls.filter((targetUrl) =>
                url.startsWith(targetUrl),
              );
              return result.length > 0;
            }
          }

          return false;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(([url, customPopup]) => {
        if (
          this.popupKey === "custom-popup-1" &&
          customPopup.properties.actionUrl === "/products/categories/bigbets"
        ) {
          this.sharedService.enableAccessBitBetsUrl();
        }

        if (customPopup.properties.displayOneTimePerUser) {
          if (
            this.popupKey &&
            !this._customPopupService.customPopupAlreadyInLocalStorage(
              this.popupKey,
            )
          ) {
            this._customPopupService.setCustomPopupInLocalStorage(
              this.popupKey,
            );
            this.showModal();
          }
        } else {
          if (customPopup.properties.displayOneTimePerSession) {
            if (
              this.popupKey &&
              !this._customPopupService.customPopupAlreadyDisplayed(
                this.popupKey,
              )
            ) {
              this._customPopupService.setCustomPopupAlreadyDisplayed(
                this.popupKey,
              );
              this.showModal();
            }
          } else {
            this.showModal();
          }
        }
      });
  }

  showModal(): void {
    if ($("#" + this.popupKey)) {
      setTimeout(() => {
        $("#black-overlay").addClass("visible");
        $("#" + this.popupKey).modal("show");
      }, 500);
    }
  }

  closeModal(): void {
    if ($("#" + this.popupKey)) {
      $("#black-overlay").removeClass("visible");
      $("#" + this.popupKey).modal("hide");
    }
  }

  imgClicked(actionUrl?: string): void {
    this.closeModal();

    if (actionUrl?.startsWith("/")) {
      this._router.navigateByUrl(actionUrl);
    } else {
      window.open(actionUrl, "_blank");
    }
  }
}
