import { NgClass } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { SharedService } from "src/app/shared/services/shared.service";
import { DevicesService } from "src/app/core/services/devices.service";
import { FrontpageSectionService } from "src/app/services/frontpage-section.service";
import { HomePageService } from "src/app/services/home-page.service";

declare var $: any;

@Component({
  selector: "fc-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class HomeComponent implements OnInit {
  sharedService = inject(SharedService);
  homePageService = inject(HomePageService);
  private _devicesService = inject(DevicesService);
  private _frontpageSectionService = inject(FrontpageSectionService);

  isMobile: boolean = false;

  bannerImages = this.homePageService.bannerImages;
  bannerLinks = this.homePageService.bannerLinks;

  sections = this._frontpageSectionService.sections;

  badgesMap = this.homePageService.badgesKeyMap;

  ngOnInit() {
    this.isMobile = this._devicesService.isMobile();
    this.checkIfBrowserIsIE();
  }

  navigateToUrl(url: string | undefined, event: Event) {
    if (!url) {
      return;
    }

    if (url.startsWith("http") || url.startsWith("https")) {
      if (event) {
        event.stopPropagation();
      }

      window.open(url, "_blank");
    } else {
      this.sharedService.navigateToUrl(url);
    }
  }

  private checkIfBrowserIsIE() {
    if (this.sharedService.browserIsIE()) {
      $("#ie-modal").modal("show");
    }
  }

  closeIeModal() {
    $("#ie-modal").modal("hide");
  }
}
