import { z } from "zod";

export const CustomPopupKeyNames = [
  "custom-popup-1",
  "custom-popup-2",
] as const;
export type CustomPopupKey = (typeof CustomPopupKeyNames)[number];

export type CustomPopup = {
  readonly id: number;
  readonly key: CustomPopupKey;
  readonly displayName: string;
  readonly properties: CustomPopupProperties;
};

export type CustomPopupProperties = {
  readonly isActive?: boolean;
  readonly title?: string | null;
  readonly imageName?: string;
  readonly actionUrl?: string;
  readonly targetUrls?: string;
  readonly displayOneTimePerUser?: boolean;
  readonly displayOneTimePerSession?: boolean;
  readonly showCloseButton?: boolean;
};

export const CUSTOM_POPUP_PROPERTIES_SCHEMA = z.object({
  isActive: z.boolean().optional(),
  title: z.union([z.string(), z.null()]).optional(),
  imageName: z.string().optional(),
  actionUrl: z.string().optional(),
  targetUrls: z.string().optional(),
  displayOneTimePerUser: z.boolean().optional(),
  displayOneTimePerSession: z.boolean().optional(),
  showCloseButton: z.boolean().optional(),
});
