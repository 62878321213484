import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewChecked,
  inject,
} from "@angular/core";
import { NgClass } from "@angular/common";
import { Store } from "@ngrx/store";
import { SharedService } from "src/app/shared/services/shared.service";
import {
  EventBrokerService,
  IEventListener,
} from "../../../../shared/services/eventBroker.service";
import { CategoriesService } from "src/app/services/categories.service";
import { AppState } from "src/app/store/state/app.state";
import { DisplayStyle } from "src/app/store/models/ISiteSettings";
import { setSiteSettingsDisplayStyleAction } from "src/app/store/actions/SiteSettings.actions";

declare var $: any;

@Component({
  selector: "fc-menu-tree",
  templateUrl: "./menu-tree.component.html",
  styleUrls: ["./menu-tree.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class MenuTreeComponent implements AfterViewChecked {
  @Input() isLeftNav?: boolean;
  @Input() isMobile?: boolean;

  private _categoriesService = inject(CategoriesService);

  private resizeMenuEventListener: IEventListener;
  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  categories = this._categoriesService.categories;

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private eventBroker: EventBrokerService,
  ) {
    this.resizeMenuEventListener = eventBroker.listen<void>("resizeMenu", () =>
      this.resizeMenu(),
    );
  }

  ngAfterViewChecked(): void {
    if (!this.isLeftNav) {
      this.listenEvent();
    }
    this.resizeMenu();
  }

  private resizeMenu() {
    if (!this.isMobile) {
      if (!$(".footer")[0]) {
        return;
      }
      const footerTop: number = $(".footer")[0].getBoundingClientRect().top;
      const winHeight: number = $(window).height();
      $(".side-menu-container").css({
        height:
          winHeight - 240 - (footerTop < winHeight ? winHeight - footerTop : 0),
        width: $(".leftCol").width() - 10,
      });
    } else {
      $(".leftCol").hide();
    }
  }

  private listenEvent(): void {
    $(".navbar-toggler.menu-toggle, .btn.menu-toggle")
      .off("click")
      .on("click", function (e: any) {
        e.preventDefault();

        $(".tree-menu").toggleClass("active");
      });
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName);
  }

  navigateToCategoryId(categoryId?: number) {
    this.sharedService.navigateToCategoryId(categoryId ?? -1);
  }

  displayInventoryModal() {
    $(".tree-menu").toggleClass("active");
    $("#inventory-modal").modal("show");
  }

  openEPOP() {
    window.open(
      "https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx",
      "_blank",
    );
  }

  openSellSheets() {
    window.open(
      "https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui",
      "_blank",
    );
  }

  switchDisplayStyle() {
    this.store.dispatch(
      setSiteSettingsDisplayStyleAction({
        displayStyle:
          this.displayStyle === DisplayStyle.Cards
            ? DisplayStyle.Rows
            : DisplayStyle.Cards,
      }),
    );
    $(".tree-menu").toggleClass("active");

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>("productsLoaded", undefined);
    }, 500);
  }
}
