import { Component, OnInit, Input } from '@angular/core';

import { SharedService } from "src/app/shared/services/shared.service";
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class HeaderComponent implements OnInit {
  @Input() categoryName: string | null = null;
  @Input() categoryChildName: string | null = null;
  @Input() categoryChildChildName: string | null = null;
  @Input() displayDivider?: boolean;

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
  }
}
