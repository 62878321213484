import { Component, OnInit, Input } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
    selector: 'fc-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    standalone: true
})
export class HeadingComponent implements OnInit {
  @Input() categoryName?: string
  @Input() firstParentName: string | null = null

  headerImageName?: string

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.categoryName !== undefined && this.categoryName !== null) {
      switch (this.categoryName) {
        case "Large Format":
          this.headerImageName = "header-large-format.jpg"

          if (this.firstParentName && this.firstParentName === "Big Bets") {
            this.headerImageName = "header-large-format-big-bets.jpg"
          }
          break;
        case "Small Format":
          this.headerImageName = "header-small-format.jpg"

          if (this.firstParentName && this.firstParentName === "Big Bets") {
            this.headerImageName = "header-small-format-big-bets.jpg"
          }
          break;
        case "Shelving/Clips & Misc":
          this.headerImageName = "header-shelving.jpg"
          break;
        case "Walmart":
          this.headerImageName = "header-walmart.jpg"
          break;
        case "Temporary":
          this.headerImageName = "header-temporary.jpg"
          break;
        case "Full":
          if (this.sharedService.environmentIsMCOE()) {
            this.headerImageName = "header-full.jpg"
          } else if (this.sharedService.environmentIsFSV()) {
            this.headerImageName = "fsv/header-full.jpg"
          } else if (this.sharedService.environmentIsPBNA()) {
            this.headerImageName = "pbna/Full Catalog Picture.png";
          }
          break;
        case "Special Offers":
          this.headerImageName = "header-special-offers.jpg"
          break;
        case "By Type":
          this.headerImageName = "fsv/Chapter_Pages_By_Type.jpg"
          break;
        case "By Design":
          this.headerImageName = "fsv/Chapter_Pages_By_Design.jpg"
          break;
        case "CHART Displays":
          this.headerImageName = "fsv/Chapter_Pages_Chart_Displays.jpg"
          break;
        case "Header Cards":
          this.headerImageName = "fsv/Chapter_Pages_Header_Cards.jpg"
          break;
        case "Accessories":
          this.headerImageName = "fsv/Chapter_Pages_Acc.jpg"
          break;
        case "Flex":
          this.headerImageName = "pbna/Flex Category.png"
          break;
        case "Brand":
          this.headerImageName = "pbna/Brand Category.png"
          break;
        case "Electric Barrel":
          this.headerImageName = "pbna/Electric Barrel Category.png"
          break;
        case "Account Programs":
          this.headerImageName = "pbna/Account Programs Category.png"
          break;
        case "Glides":
          this.headerImageName = "pbna/Glides Category.png"
          break;
        default:
          break;
      }
    }
  }
}
