import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  inject,
  signal,
} from "@angular/core";
import { ActivatedRoute, Router, Event, NavigationEnd } from "@angular/router";

import { DevicesService } from "src/app/core/services/devices.service";
import {
  EventBrokerService,
  IEventListener,
} from "../../shared/services/eventBroker.service";

import { Store } from "@ngrx/store";
import {
  IBreadCrumb,
  IBreadCrumbCategory,
} from "../../store/models/IBreadCrumb";
import { AppState } from "../../store/state/app.state";
import {
  ISiteSettings,
  DisplayStyle,
} from "src/app/store/models/ISiteSettings";

import { SharedService } from "src/app/shared/services/shared.service";
import {
  catchError,
  delay,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from "rxjs";
import { setSiteSettingsBugsAction } from "src/app/store/actions/SiteSettings.actions";
import { setBreadCrumbAction } from "src/app/store/actions/BreadCrumb.actions";
import { FritolayProductViewerComponent } from "@gc/fritolay-product-viewer";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { HeadingComponent } from "../print/heading/heading.component";
import { CardComponent } from "./card/card.component";
import { HeaderComponent } from "./header/header.component";
import { SizeSpyDirective } from "../../shared/size-spy.directive";
import { MenuTreeComponent } from "../../shared/components/menu/menu-tree/menu-tree.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { CategoriesService } from "src/app/services/categories.service";
import { CategoryWithProducts } from "src/app/types/category.type";
import { Product } from "src/app/types/product.type";
import { ProductsService } from "src/app/services/products.service";
import { SearchService } from "src/app/services/search.service";

declare var $: any;

@Component({
  selector: "fc-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    BreadcrumbComponent,
    MenuTreeComponent,
    SizeSpyDirective,
    HeaderComponent,
    NgFor,
    CardComponent,
    HeadingComponent,
    NgxImageZoomModule,
    FritolayProductViewerComponent,
  ],
})
export class ProductComponent implements OnInit, OnDestroy {
  @Input() isPrint: boolean = false;

  categoriesData?: any[];
  printNumberOfPages = 0;
  printBugHeadingPage1 = 0;
  printBugHeadingPage2 = 0;
  categoryDetails: any;
  bugReferenceName1 = "";
  bugReferenceName2 = "";
  isBugs: boolean = false;
  isMobile: boolean = false;
  displayLoader: boolean = false;
  currentProductsSection = "product-section-0";
  currentCategoryName: string | null = null;
  currentCategoryChildName: string | null = null;
  currentCategoryChildChildName: string | null = null;
  isPrintFullCatalog: boolean = false;
  currentSearchType = 0;
  isAlreadyLoaded: boolean = false;
  pageHeight = 1580;
  headerHeight = 92;
  productsRowHeight = 412;
  todayString?: string;
  display360ProductViewerInThumbnailModal = false;
  thumbnailMagnification = 1;
  canListenMagnificationChange = false;
  thumbnailInterval?: number;
  isCardLayout = true;

  private sectionChangedListener: IEventListener;
  private loadedEventListener: IEventListener;
  private subscriptions = new Subscription();

  ////////////////////////////////////////////////////////////////////////
  private _categoriesService = inject(CategoriesService);
  private _productsService = inject(ProductsService);
  private _searchService = inject(SearchService);

  categoriesWithProducts = signal<CategoryWithProducts[]>([]);
  products = signal<Product[]>([]);

  categoryProducts: Product[] = [];
  categoryProducts2: Product[] = [];

  private _updateCategoriesWithProducts = new Subject<Observable<void>>();
  private _updateProducts = new Subject<Observable<void>>();

  ////////////////////////////////////////////////////////////////////////

  constructor(
    private store: Store<AppState>,
    private eventBroker: EventBrokerService,
    private router: Router,
    private route: ActivatedRoute,
    private devicesService: DevicesService,
    public sharedService: SharedService,
  ) {
    this.subscriptions.add(
      this._updateCategoriesWithProducts
        .pipe(switchMap((obs) => obs))
        .subscribe(),
    );
    this.subscriptions.add(
      this._updateProducts.pipe(switchMap((obs) => obs)).subscribe(),
    );

    this.subscriptions.add(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this.isAlreadyLoaded) {
            this.loadProductsPage();
          }
        }
      }),
    );

    this.sectionChangedListener = eventBroker.listen<{}>(
      "sectionChanged",
      (data: {}) => this.onSectionChange(data),
    );

    this.store
      .select((state) => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
        this.isBugs = s ? s.isBugs : false;
      });

    this.loadedEventListener = eventBroker.listen<void>("productsLoaded", () =>
      this.recomputeHeight(),
    );

    this.loadedEventListener = eventBroker.listen<void>(
      "productsContainerSizeChanged",
      () => this.recomputeHeight(),
    );
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile();
    this.isAlreadyLoaded = true;
    this.loadProductsPage();
  }

  public ngOnDestroy() {
    this.sectionChangedListener.ignore();
    this.loadedEventListener.ignore();
    this.subscriptions.unsubscribe();
  }

  private recomputeHeight(): void {
    let h = 0;
    $(".categoryContainer").each((index: any, value: any) => {
      h += $(value).height();
    });

    $(".catalog-content").css({
      height: `${h}px`,
    });
    $("#products-scroll-spy").css({
      height: `${h}px`,
    });
  }

  private generateCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    this.todayString = mm + "/" + dd + "/" + yyyy;
  }

  private loadProductsPage(): void {
    this.categoryProducts = [];
    this.categoryProducts2 = [];

    // TODO: Move print into its own component and use query parameters to pass options
    if (this.isPrint) {
      document.getElementById("wrapper")!.style.height = "unset";

      this.generateCurrentDate();

      if (this.route.snapshot.paramMap.get("fullCatalog") === "full") {
        this.isPrintFullCatalog = true;
      } else {
        this.isPrintFullCatalog = false;
      }

      let categoriesIdsUrl = this.route.snapshot.paramMap.get("categoriesIds");
      var categoriesIds: any = [];
      let bugId = this.route.snapshot.paramMap.get("bugsIds");

      if (categoriesIdsUrl !== "null") {
        let categoriesIdsUrlSplit = categoriesIdsUrl?.split("&");

        categoriesIdsUrlSplit?.forEach((categoryId) => {
          let categoryIdToInt = parseInt(categoryId);

          categoriesIds.push(categoryIdToInt);
        });
      }

      this.getProductsByCategoriesAndBugs(categoriesIds, bugId ?? "");
    } else {
      if (this.route.snapshot.paramMap.get("type") === "categories") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: false }));

        if (this.route.snapshot.paramMap.get("objectId") === "all") {
          this._updateCategoriesWithProducts.next(
            this.getAllCategoriesProducts(),
          );
        } else {
          this._updateCategoriesWithProducts.next(
            this.getCategoryProducts(
              parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
            ),
          );
        }
      } else if (this.route.snapshot.paramMap.get("type") === "bugs") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductsByBug(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "search") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductsByKeyword(
            decodeURIComponent(
              this.route.snapshot.paramMap.get("objectId") ?? "",
            ),
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "colors") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductsByColor(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "product") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductById(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
          ),
        );
      }
    }
  }

  private getAllCategoriesProducts(): Observable<void> {
    return this._categoriesService.getCategoriesWithProducts().pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((categories) => {
        this.generateSingleBreadcrumb({ name: "Full Catalog", id: "all" });

        this.categoriesWithProducts.set(categories);
        this.currentCategoryName =
          categories.length > 0 ? categories[0].name : null;
        this.currentCategoryChildName =
          categories.length > 0 && categories[0].children.length > 0
            ? categories[0].children[0].name
            : null;
        this.currentCategoryChildChildName =
          categories.length > 0 &&
          categories[0].children.length > 0 &&
          categories[0].children[0].children.length > 0
            ? categories[0].children[0].children[0].name
            : null;

        this.scrollTop();

        this.displayLoader = false;
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
  }

  private getCategoryProducts(categoryId: number): Observable<void> {
    return this._categoriesService.getCategoryWithProducts(categoryId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of(undefined);
      }),
      map((category) => {
        if (category) {
          this.currentCategoryName = category.name;
          this.currentCategoryChildName =
            category.children.length > 0 ? category.children[0].name : null;
          this.currentCategoryChildChildName =
            category.children.length > 0 &&
            category.children[0].children.length > 0
              ? category.children[0].children[0].name
              : null;

          this.displayLoader = false;
          this.categoriesWithProducts.set([category]);
          this.generateMultipleBreadcrumb(category);
          this.scrollTop();
        }
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
  }

  private getProductsByCategoriesAndBugs(
    categoriesIds: number[],
    bugId: string,
  ): void {
    let previousHeadingName = "";

    if (categoriesIds.length > 0) {
      const publicSubscription = this._categoriesService
        .getCategoriesWithProducts(categoriesIds)
        .subscribe((categories) => {
          // Flat all categories into one array with specifics types
          // Group products by array of 3 objects (representing 1 row)
          let printData: any[] = [];
          categories.forEach((l1Category) => {
            let headingName =
              l1Category.parent?.parent?.name ??
              l1Category.parent?.name ??
              l1Category.name;

            if (headingName !== previousHeadingName) {
              printData.push({
                type: "heading",
                height: this.pageHeight,
                name: headingName,
                firstParentName: l1Category.name,
              });
            }

            previousHeadingName = headingName;

            generateHeaderAndProductsArray(
              l1Category,
              null,
              null,
              this.headerHeight,
              this.productsRowHeight,
            );
            l1Category.children.forEach((l2Category: any) => {
              generateHeaderAndProductsArray(
                l2Category,
                l1Category,
                null,
                this.headerHeight,
                this.productsRowHeight,
              );
              l2Category.children.forEach((l3Category: any) => {
                generateHeaderAndProductsArray(
                  l3Category,
                  l2Category,
                  l1Category,
                  this.headerHeight,
                  this.productsRowHeight,
                );
              });
            });

            function sort(element: CategoryWithProducts): CategoryWithProducts {
              return {
                ...element,
                products: [...element.products].sort(
                  (a, b) => a.sortId - b.sortId,
                ),
              };
            }

            function generateHeaderAndProductsArray(
              element: CategoryWithProducts,
              elementParent: CategoryWithProducts | null,
              elementParentParent: CategoryWithProducts | null,
              headerHeight: number,
              productsRowHeight: number,
            ) {
              element = sort(element);

              let categoryName: string = "";
              let categoryChildName: string | null = "";
              let categoryChildChildName: string | null = "";

              if (elementParentParent != null && elementParent !== null) {
                categoryName = elementParentParent.name;
                categoryChildName = elementParent.name;
                categoryChildChildName = element.name;
              } else if (
                elementParentParent == null &&
                elementParent !== null
              ) {
                categoryName = elementParent.name;
                categoryChildName = element.name;
                categoryChildChildName = null;
              } else {
                categoryName = element.name;
                categoryChildName = null;
                categoryChildChildName = null;
              }

              if (element.products.length > 0) {
                printData.push({
                  type: "header",
                  height: headerHeight,
                  id: element.id,
                  name: element.name,
                  categoryName: categoryName,
                  categoryChildName: categoryChildName,
                  categoryChildChildName: categoryChildChildName,
                });

                const products = [...element.products];
                while (products.length) {
                  printData.push({
                    type: "products",
                    height: productsRowHeight,
                    data: products.splice(0, 3),
                  });
                }
              }
            }

            // Group header and first line of row together into 1 block
            const printDataClone = [...printData];
            var printDataGroup = [];

            var i = 0;
            while (i < printDataClone.length) {
              let currentObject = printDataClone[i];

              if (currentObject["type"] === "header") {
                printDataGroup.push({
                  type: "headerAndProducts",
                  height: this.headerHeight + this.productsRowHeight,
                  data: printDataClone.slice(i, i + 2),
                });

                i++;
              } else {
                printDataGroup.push(printDataClone.slice(i, i + 1)[0]);
              }

              i++;
            }

            // Calculate height for each elements and generate appropiate pages
            this.calculElementsHeightAndGeneratePages(
              "products",
              printDataGroup,
              0,
              (printDataPage: any, currentPage: any) => {
                this.categoriesData = printDataPage;
                this.printNumberOfPages = currentPage;

                this.generateBugsData(bugId, publicSubscription, currentPage);
              },
            );
          });
        });
    } else {
      this.generateBugsData(bugId, null, 0);
    }
  }

  private generateBugsData(
    bugId: string,
    subscription: Subscription | null,
    pageNumber: number,
  ) {
    if (bugId !== null) {
      var bugReferenceName1: string | null = null;
      var bugProductsId1: number | null = null;
      var bugReferenceName2: string | null = null;
      var bugProductsId2: number | null = null;

      switch (bugId) {
        case "special-offers":
          bugReferenceName1 = "Special Offers";
          bugProductsId1 = 4;
          break;
        case "header-cards":
          bugReferenceName1 = "Header Cards";
          bugProductsId1 = 4;
          break;
        case "accessories":
          bugReferenceName1 = "Accessories";
          bugProductsId1 = 3;
          break;
        case "accessories&header-cards":
          bugReferenceName1 = "Accessories";
          bugProductsId1 = 3;
          bugReferenceName2 = "Header Cards";
          bugProductsId2 = 4;
          break;
        case "header-cards&accessories":
          bugReferenceName1 = "Header Cards";
          bugProductsId1 = 4;
          bugReferenceName2 = "Accessories";
          bugProductsId2 = 3;
          break;
      }

      if (bugReferenceName1 !== null && bugProductsId1 !== null) {
        if (subscription !== null) {
          subscription.unsubscribe();
        }

        // TODO: Combine all this into one subscription
        this._productsService.getProductsByBug(bugProductsId1).subscribe(
          (products) => {
            this.groupBugsBy3(
              products,
              pageNumber,
              "bug1",
              (currentPage: any) => {
                this.bugReferenceName1 = bugReferenceName1 ?? "";

                if (bugReferenceName2 !== null && bugProductsId2 !== null) {
                  this._productsService
                    .getProductsByBug(bugProductsId2)
                    .subscribe(
                      (products) => {
                        this.groupBugsBy3(
                          products,
                          currentPage,
                          "bug2",
                          (currentPage: any) => {
                            this.bugReferenceName2 = bugReferenceName2 ?? "";
                          },
                        );
                      },
                      (error) => {
                        console.error(
                          "there was an error sending the query",
                          error,
                        );
                      },
                    );
                }
              },
            );
          },
          (error) => {
            console.error("there was an error sending the query", error);
          },
        );
      }
    }
  }

  private groupBugsBy3(
    products: Product[],
    page: number,
    bugsNumber: string,
    _callback: any,
  ) {
    var printBugsData = [];

    while (products.length) {
      printBugsData.push({
        type: "products",
        height: this.productsRowHeight,
        data: products.splice(0, 3),
      });
    }

    this.calculElementsHeightAndGeneratePages(
      bugsNumber,
      printBugsData,
      page,
      (printDataPage: any, currentPage: any) => {
        if (bugsNumber === "bug1") {
          this.categoryProducts = printDataPage;
        } else if (bugsNumber === "bug2") {
          this.categoryProducts2 = printDataPage;
        }

        this.printNumberOfPages = currentPage;

        _callback(currentPage);
      },
    );
  }

  private calculElementsHeightAndGeneratePages(
    itemType: string,
    printDataGroup: any,
    page: number,
    _callback: any,
  ) {
    var printDataPage = [];
    var currentHeight = 0;
    var currentPage = page;

    if (itemType === "bug1") {
      currentPage = page += 1;
      this.printBugHeadingPage1 = currentPage;
      generateHeader(null);
    } else if (itemType === "bug2") {
      currentPage = page += 1;
      this.printBugHeadingPage2 = currentPage;
      generateHeader(null);
    }

    for (let index = 0; index < printDataGroup.length; index++) {
      const element = printDataGroup[index];

      if (element["type"] === "heading") {
        generateHeader(element);
        currentPage++;

        printDataPage.push({
          type: "header",
          value: currentPage,
        });
      } else {
        if (currentHeight + element["height"] + 50 <= this.pageHeight) {
          printDataPage.push(element);

          currentHeight += element["height"];
        } else {
          generateHeader(element);
          currentHeight += element["height"];
        }
      }
    }

    function generateHeader(element: any) {
      currentPage++;

      printDataPage.push({
        type: "header",
        value: currentPage,
      });

      if (element !== null) {
        printDataPage.push(element);
      }

      currentHeight = 0;
    }

    _callback(printDataPage, currentPage);
  }

  private getProductsByBug(bugId: number): Observable<void> {
    return this._productsService.getProductsByBug(bugId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
  }

  private getProductsByKeyword(keyword: string): Observable<void> {
    return this._searchService.searchProduct(keyword).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
  }

  private getProductsByColor(colorId: number): Observable<void> {
    return this._productsService.getProductsByColor(colorId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
    );
  }

  private getProductById(productId: number): Observable<void> {
    return this._productsService.getProduct(productId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of(undefined);
      }),
      map((product) => {
        this.displayLoader = false;
        this.products.set(product ? [product] : []);
        this.scrollTop();
      }),
    );
  }

  private generateSingleBreadcrumb(category: IBreadCrumbCategory): void {
    const breadCrumb: IBreadCrumb = {
      category: category,
      categoryChild: null,
      categoryChildChild: null,
    };

    this.store.dispatch(setBreadCrumbAction(breadCrumb));
  }

  private generateMultipleBreadcrumb(category: CategoryWithProducts): void {
    const c = this._categoriesService.flattenedCategoriesMap()[category.id];
    if (c.parentId) {
      const parent =
        this._categoriesService.flattenedCategoriesMap()[c.parentId];
      if (parent.parentId) {
        const greatParent =
          this._categoriesService.flattenedCategoriesMap()[parent.parentId];
        this.store.dispatch(
          setBreadCrumbAction({
            category: { id: greatParent.id.toString(), name: greatParent.name },
            categoryChild: { id: parent.id.toString(), name: parent.name },
            categoryChildChild: { id: c.id.toString(), name: c.name },
          }),
        );
      } else {
        this.store.dispatch(
          setBreadCrumbAction({
            category: { id: parent.id.toString(), name: parent.name },
            categoryChild: { id: c.id.toString(), name: c.name },
            categoryChildChild: null,
          }),
        );
      }
    } else {
      this.store.dispatch(
        setBreadCrumbAction({
          category: { id: c.id.toString(), name: c.name },
          categoryChild: null,
          categoryChildChild: null,
        }),
      );
    }
  }

  onSectionChange(currentSectionData: any) {
    this.currentProductsSection = currentSectionData["sectionId"];
    this.currentCategoryName = currentSectionData["categoryName"];
    this.currentCategoryChildName = currentSectionData["categoryChildName"];
    this.currentCategoryChildChildName =
      currentSectionData["categoryChildChildName"];
  }

  // onDownloadCardClicked(product: Product, imageId: number = 0) {
  //   this.downloadProduct = product;
  //   this.downloadImageId = imageId;

  //   setTimeout(() => {
  //     $("#black-overlay").addClass("visible");
  //     $("#download-modal").modal("show");
  //   }, 500);
  // }

  // onDownloadModalClicked(): void {
  //   if (this.thumbnailProduct) {
  //     this.onDownloadCardClicked(this.thumbnailProduct, this.thumbnailImageId);
  //   }
  // }

  // onThumbnailClicked(product: Product) {
  //   this.thumbnailProduct = product;
  //   this.thumbnailProductHas360 = product.properties.has360View ?? false;
  //   this.thumbnailImageId = 0;
  //   this.thumbnailImage =
  //     product.images.length > 0
  //       ? product.images[0].image
  //       : "assets/images/common/no_image.svg";
  //   setTimeout(() => {
  //     $("#black-overlay").addClass("visible");
  //     $("#thumbnail-modal").modal("show");
  //     this.thumbnailMagnification = 1.5;
  //     this.canListenMagnificationChange = true;
  //     const modalBodyElement = document.querySelector(
  //       "#thumbnail-modal .modal-body",
  //     ) as HTMLElement;
  //     const ngxImageZoomFullElement = document.querySelector(
  //       ".ngxImageZoomFull",
  //     ) as HTMLImageElement;
  //     if (!ngxImageZoomFullElement || !modalBodyElement) {
  //       return;
  //     }
  //     this.thumbnailInterval = window.setInterval(() => {
  //       if (
  //         modalBodyElement.offsetHeight !== 0 &&
  //         ngxImageZoomFullElement.height !== 0
  //       ) {
  //         clearInterval(this.thumbnailInterval);
  //         this.thumbnailMagnification =
  //           modalBodyElement.offsetHeight / ngxImageZoomFullElement.height +
  //           0.5;
  //         this.canZoom = true;
  //       }
  //     }, 500);
  //   }, 500);
  // }

  // closeThumbnailModal() {
  //   $("#black-overlay").removeClass("visible");
  //   $("#thumbnail-modal").modal("hide");
  //   this.closeFritolayProductViewer();
  //   this.thumbnailProduct = null;
  //   this.canListenMagnificationChange = false;
  //   this.canZoom = false;
  //   if (this.thumbnailInterval) {
  //     clearInterval(this.thumbnailInterval);
  //   }
  // }

  private scrollTop() {
    setTimeout(() => {
      $(".container.content").scrollTop(0);

      if (document.getElementById("products-scroll-spy") !== null) {
        document.getElementById("products-scroll-spy")?.scrollBy(0, 1);
        $(".main-container").scrollTop(1);
      }
    }, 0);
  }

  button360ClickedEmitter(): void {
    if (!this.display360ProductViewerInThumbnailModal) {
      this.display360ProductViewerInThumbnailModal = true;

      if (!document.getElementById("button-360")) {
        return;
      }
      document.getElementById("button-360")?.classList.add("active");
    } else {
      this.closeFritolayProductViewer();
    }
  }

  closeFritolayProductViewer(): void {
    this.display360ProductViewerInThumbnailModal = false;

    if (!document.getElementById("button-360")) {
      return;
    }
    document.getElementById("button-360")?.classList.remove("active");
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.canListenMagnificationChange) {
      const modalBodyElement = document.querySelector(
        "#thumbnail-modal .modal-body",
      ) as HTMLElement;
      const ngxImageZoomFullElement = document.querySelector(
        ".ngxImageZoomFull",
      ) as HTMLImageElement;

      if (!ngxImageZoomFullElement || !modalBodyElement) {
        return;
      }

      this.thumbnailMagnification =
        modalBodyElement.offsetHeight / ngxImageZoomFullElement.height + 0.5;
    }
  }

  // thumbnailModalItemChange(id: number): void {
  //   this.thumbnailImageId = id;
  //   this.thumbnailImage = this.thumbnailProduct?.images[id].image ?? "";
  // }
}
