import { createAction, props } from "@ngrx/store";
import { DisplayStyle } from "../models/ISiteSettings";



export const SET_SITE_SETTINGS_BUGS = 'SET_SITE_SETTINGS_BUGS';
export const setSiteSettingsBugsAction = createAction(
  SET_SITE_SETTINGS_BUGS,
  props<{ isBugs: boolean }>()
);

export const SET_SITE_SETTINGS_DISPLAY_STYLE = 'SET_SITE_SETTINGS_DISPLAY_STYLE';
export const setSiteSettingsDisplayStyleAction = createAction(
  SET_SITE_SETTINGS_DISPLAY_STYLE,
  props<{ displayStyle: DisplayStyle }>()
);