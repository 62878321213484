import { NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { StronglyTypedMatDialog } from "../../directives/strongly-typed-mat-dialog";

export type InfoPopupData = {
  title?: string;
  text?: string;
  buttonClass?: string;
  buttonText?: string;
};

@Component({
  selector: "fc-info-popup",
  standalone: true,
  imports: [NgClass, MatButtonModule, MatDialogModule],
  templateUrl: "./info-popup.component.html",
  styleUrl: "./info-popup.component.scss",
})
export class InfoPopupComponent extends StronglyTypedMatDialog<
  InfoPopupData,
  void
> {
  data = this._data;
}
