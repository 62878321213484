@if (!isPrint) {
  <div class="row padding">
    <!-- Left Menu -->
    <div
      class="leftCol"
      [ngClass]="{ 'col-12 px-0': isMobile, 'col-3 px-0': !isMobile }"
    >
      @if (!isMobile) {
        <div class="side-menu-container">
          @if (!isBugs) {
            <fc-breadcrumb></fc-breadcrumb>
          }
          <fc-menu-tree [isMobile]="isMobile" [isLeftNav]="true"></fc-menu-tree>
        </div>
      }
    </div>

    <div
      class="cmt-2 catalog-content"
      appSizeSpy
      [ngClass]="{ 'col-12 px-0': isMobile, 'col-9 px-0': !isMobile }"
    >
      <!-- Loader -->
      @if (displayLoader) {
        <div class="loader-container">
          <div class="loader"></div>
          <div class="text">
            <p>Loading products...</p>
          </div>
        </div>
      }

      <!-- Products displayed by categories -->
      @if (!displayLoader && !isBugs) {
        <div class="w-100">
          @if (categoriesWithProducts().length > 0) {
            <div id="products-scroll-spy">
              <div class="sticky-top">
                <div id="main-header" class="row">
                  <div class="col-12 px-0 pt-2">
                    <fc-header
                      [categoryName]="currentCategoryName"
                      [categoryChildName]="currentCategoryChildName"
                      [categoryChildChildName]="currentCategoryChildChildName"
                      [displayDivider]="false"
                    >
                    </fc-header>
                  </div>
                </div>
              </div>

              @for (
                l1Category of categoriesWithProducts();
                track l1Category.id;
                let l1Index = $index
              ) {
                <div class="categoryContainer">
                  <div
                    class="w-100 childs"
                    [id]="'product-section-' + l1Index"
                    [attr.data-category-name]="l1Category.name"
                    [attr.data-category-child-name]="null"
                    [attr.data-category-child-child-name]="null"
                  >
                    <div class="row">
                      @if (l1Category.products.length > 0) {
                        <div class="col-12 px-0 pt-2">
                          <fc-header
                            [categoryName]="l1Category.name"
                            [displayDivider]="true"
                          >
                          </fc-header>
                        </div>

                        <div class="col-12 px-0">
                          <div class="row">
                            @for (
                              product of l1Category.products;
                              track product.id;
                              let l1ProductIndex = $index
                            ) {
                              <div
                                [ngClass]="{
                                  'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2':
                                    isCardLayout,
                                  'cards list col-sm-12': !isCardLayout,
                                }"
                              >
                                <div class="row">
                                  <fc-card
                                    class="fc-card"
                                    [product]="product"
                                    [isPrint]="isPrint"
                                  >
                                  </fc-card>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </div>

                    @for (
                      l2Category of l1Category.children;
                      track l2Category.id;
                      let l2Index = $index
                    ) {
                      <div
                        class="w-100 childs-childs"
                        [id]="
                          'product-section-' + l1Index + '-child-' + l2Index
                        "
                        [attr.data-category-name]="l1Category.name"
                        [attr.data-category-child-name]="l2Category.name"
                      >
                        @if (l2Category.products.length > 0) {
                          <div class="row">
                            <div class="col-12 px-0 pt-2">
                              <fc-header
                                [categoryName]="l1Category.name"
                                [categoryChildName]="l2Category.name"
                                [displayDivider]="true"
                              >
                              </fc-header>
                            </div>
                            <div class="col-12 px-0">
                              <div class="row">
                                @for (
                                  product of l2Category.products;
                                  track product.id;
                                  let l2ProductIndex = $index
                                ) {
                                  <div
                                    [ngClass]="{
                                      'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2':
                                        isCardLayout,
                                      'cards list col-sm-12': !isCardLayout,
                                    }"
                                  >
                                    <div class="row">
                                      <fc-card
                                        class="fc-card"
                                        [product]="product"
                                        [isPrint]="isPrint"
                                      >
                                      </fc-card>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        }
                        @for (
                          l3Category of l2Category.children;
                          track l3Category.id;
                          let l3Index = $index
                        ) {
                          <div
                            class="w-100 childs-childs-childs"
                            [id]="
                              'product-section-' +
                              l1Index +
                              '-child-' +
                              l2Index +
                              '-child-' +
                              l3Index
                            "
                            [attr.data-category-name]="l1Category.name"
                            [attr.data-category-child-name]="l2Category.name"
                            [attr.data-category-child-child-name]="
                              l3Category.name
                            "
                          >
                            @if (l3Category.products.length > 0) {
                              <div class="row">
                                <div class="col-12 px-0 pt-2">
                                  <fc-header
                                    [categoryName]="l1Category.name"
                                    [categoryChildName]="l2Category.name"
                                    [categoryChildChildName]="l3Category.name"
                                    [displayDivider]="true"
                                  >
                                  </fc-header>
                                </div>
                                <div class="col-12 px-0">
                                  <div class="row">
                                    @for (
                                      product of l3Category.products;
                                      track product.id;
                                      let l3ProductIndex = $index
                                    ) {
                                      <div
                                        [ngClass]="{
                                          'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2':
                                            isCardLayout,
                                          'cards list col-sm-12': !isCardLayout,
                                        }"
                                      >
                                        <div class="row">
                                          <fc-card
                                            class="fc-card"
                                            [product]="product"
                                            [isPrint]="isPrint"
                                          >
                                          </fc-card>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- Products displayed in grid -->
      @if (!displayLoader && isBugs) {
        <div class="w-100 h-100">
          <div class="row">
            <div class="col-12 px-0">
              <div appSizeSpy class="row categoryContainer">
                @for (product of products(); track product.id) {
                  <div
                    [ngClass]="{
                      'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2':
                        isCardLayout,
                      'cards list col-sm-12': !isCardLayout,
                    }"
                  >
                    <fc-card
                      class="fc-card"
                      [product]="product"
                      [isPrint]="isPrint"
                    ></fc-card>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <!-- No results -->
        @if (products().length === 0) {
          <div class="w-100 h-100">
            <div class="row">
              <div class="col-12">
                <div class="no-results">
                  <span>No Results Found</span>
                </div>
              </div>
            </div>
          </div>
        }
      }
    </div>
  </div>
} @else {
  <div style="margin-left: 60px; margin-right: 60px">
    @if (isPrintFullCatalog) {
      <div class="print-header">
        <div class="h-100">
          <div class="row h-100">
            <div class="col-4">
              @if (sharedService.environmentIsMCOE()) {
                <div class="print-title">MCOE Catalog</div>
              } @else if (sharedService.environmentIsFSV()) {
                <div class="print-title">PepsiCo - Foodservice</div>
              }
            </div>
            <div class="col-4">
              <div class="print-date">
                {{ todayString }}
              </div>
            </div>
            <div class="col-4">
              <div class="print-pages"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="print-heading">
        <div>
          <fc-heading [categoryName]="'Full'"></fc-heading>
        </div>
      </div>
    }

    @for (categoryData of categoriesData; track categoryData) {
      <div
        [ngClass]="{
          'print-header': categoryData['type'] === 'header',
          'print-footer': categoryData['type'] === 'footer',
          'print-heading': categoryData['type'] === 'heading',
          'print-header-products': categoryData['type'] === 'headerAndProducts',
          'print-products-row': categoryData['type'] === 'products',
        }"
      >
        @if (categoryData["type"] === "header") {
          <div class="h-100">
            <div class="row h-100">
              <div class="col-4">
                @if (sharedService.environmentIsMCOE()) {
                  <div class="print-title">MCOE Catalog</div>
                } @else if (sharedService.environmentIsFSV()) {
                  <div class="print-title">PepsiCo - Foodservice</div>
                }
              </div>
              <div class="col-4">
                <div class="print-date">
                  {{ todayString }}
                </div>
              </div>
              <div class="col-4">
                <div class="print-pages">
                  Page {{ categoryData["value"] }} / {{ printNumberOfPages }}
                </div>
              </div>
            </div>
          </div>
        }

        @if (categoryData["type"] === "footer") {
          <div></div>
        }

        @if (categoryData["type"] === "heading") {
          <div>
            <fc-heading
              [categoryName]="categoryData['name']"
              [firstParentName]="categoryData['firstParentName']"
            >
            </fc-heading>
          </div>
        }

        @if (categoryData["type"] === "headerAndProducts") {
          <div>
            @for (data of categoryData["data"]; track data) {
              <div
                [ngClass]="{
                  'product-item-header': data['type'] === 'header',
                  'product-item': data['type'] === 'product',
                }"
              >
                @if (data["type"] === "header") {
                  <div>
                    @if (
                      data["categoryChildName"] === null &&
                      data["categoryChildChildName"] === null
                    ) {
                      <fc-header
                        [categoryName]="data['categoryName']"
                        [displayDivider]="true"
                      >
                      </fc-header>
                    }

                    @if (
                      data["categoryChildName"] !== null &&
                      data["categoryChildChildName"] === null
                    ) {
                      <fc-header
                        [categoryName]="data['categoryName']"
                        [categoryChildName]="data['categoryChildName']"
                        [displayDivider]="true"
                      >
                      </fc-header>
                    }

                    @if (
                      data["categoryChildName"] !== null &&
                      data["categoryChildChildName"] !== null
                    ) {
                      <fc-header
                        [categoryName]="data['categoryName']"
                        [categoryChildName]="data['categoryChildName']"
                        [categoryChildChildName]="
                          data['categoryChildChildName']
                        "
                        [displayDivider]="true"
                      >
                      </fc-header>
                    }
                  </div>
                }

                @if (data["type"] === "products") {
                  <div>
                    @for (product of data["data"]; track product) {
                      <div class="product-item">
                        <div class="cards">
                          <fc-card
                            class="fc-card"
                            [product]="product"
                            [isPrint]="isPrint"
                          >
                          </fc-card>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }

        @if (categoryData["type"] === "products") {
          <div>
            @for (product of categoryData["data"]; track $index) {
              <div class="product-item">
                <div class="cards">
                  <fc-card
                    class="fc-card"
                    [product]="product"
                    [isPrint]="isPrint"
                  >
                  </fc-card>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }

    @if (categoryProducts && categoryProducts.length > 0) {
      <div>
        <div class="print-header">
          <div class="h-100">
            <div class="row h-100">
              <div class="col-4">
                @if (sharedService.environmentIsMCOE()) {
                  <div class="print-title">MCOE Catalog</div>
                } @else if (sharedService.environmentIsFSV()) {
                  <div class="print-title">PepsiCo - Foodservice</div>
                }
              </div>
              <div class="col-4">
                <div class="print-date">
                  {{ todayString }}
                </div>
              </div>
              <div class="col-4">
                <div class="print-pages">
                  Page {{ printBugHeadingPage1 }} / {{ printNumberOfPages }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="print-heading">
          <div>
            <fc-heading [categoryName]="bugReferenceName1"></fc-heading>
          </div>
        </div>

        @for (categoryProduct of categoryProducts; track categoryProduct) {
          <div
            [ngClass]="{
              'print-header': $any(categoryProduct)['type'] === 'header',
              'print-products-row':
                $any(categoryProduct)['type'] === 'products',
            }"
          >
            @if ($any(categoryProduct)["type"] === "header") {
              <div class="h-100">
                <div class="row h-100">
                  <div class="col-4">
                    @if (sharedService.environmentIsMCOE()) {
                      <div class="print-title">MCOE Catalog</div>
                    } @else if (sharedService.environmentIsFSV()) {
                      <div class="print-title">PepsiCo - Foodservice</div>
                    }
                  </div>
                  <div class="col-4">
                    <div class="print-date">
                      {{ todayString }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="print-pages">
                      Page {{ $any(categoryProduct)["value"] }} /
                      {{ printNumberOfPages }}
                    </div>
                  </div>
                </div>
              </div>
            }

            @if ($any(categoryProduct)["type"] === "products") {
              <div>
                @for (product of $any(categoryProduct)["data"]; track product) {
                  <div class="product-item">
                    <div class="cards">
                      <fc-card
                        class="fc-card"
                        [product]="product"
                        [isPrint]="isPrint"
                      >
                      </fc-card>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }

    @if (categoryProducts2 && categoryProducts2.length > 0) {
      <div>
        <div class="print-header">
          <div class="h-100">
            <div class="row h-100">
              <div class="col-4">
                @if (sharedService.environmentIsMCOE()) {
                  <div class="print-title">MCOE Catalog</div>
                } @else if (sharedService.environmentIsFSV()) {
                  <div class="print-title">PepsiCo - Foodservice</div>
                }
              </div>
              <div class="col-4">
                <div class="print-date">
                  {{ todayString }}
                </div>
              </div>
              <div class="col-4">
                <div class="print-pages">
                  Page {{ printBugHeadingPage2 }} / {{ printNumberOfPages }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="print-heading">
          <div>
            <fc-heading [categoryName]="bugReferenceName2"></fc-heading>
          </div>
        </div>

        @for (categoryProduct of categoryProducts2; track categoryProduct) {
          <div
            [ngClass]="{
              'print-header': $any(categoryProduct)['type'] === 'header',
              'print-products-row':
                $any(categoryProduct)['type'] === 'products',
            }"
          >
            @if ($any(categoryProduct)["type"] === "header") {
              <div class="h-100">
                <div class="row h-100">
                  <div class="col-4">
                    @if (sharedService.environmentIsMCOE()) {
                      <div class="print-title">MCOE Catalog</div>
                    } @else if (sharedService.environmentIsFSV()) {
                      <div class="print-title">PepsiCo - Foodservice</div>
                    }
                  </div>
                  <div class="col-4">
                    <div class="print-date">
                      {{ todayString }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="print-pages">
                      Page {{ $any(categoryProduct)["value"] }} /
                      {{ printNumberOfPages }}
                    </div>
                  </div>
                </div>
              </div>
            }

            @if ($any(categoryProduct)["type"] === "products") {
              <div>
                @for (product of $any(categoryProduct)["data"]; track product) {
                  <div class="product-item">
                    <div class="cards">
                      <fc-card
                        class="fc-card"
                        [product]="product"
                        [isPrint]="isPrint"
                      >
                      </fc-card>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
