import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';

import { EventBrokerService, IEventListener } from '../shared/services/eventBroker.service';

declare var $: any;

@Directive({
    selector: '[appScrollSpy]',
    standalone: true
})
export class ScrollSpyDirective implements OnDestroy {
    private currentSection?: string;
    private currentSectionData?: any;
    private test?: ElementRef
    private loadedEventListener: IEventListener;
    private spiedTags = ['DIV'];
    private childrens: any[] = [];

    constructor(
        private eventBroker: EventBrokerService,
        private _el: ElementRef
    ) {
        this.loadedEventListener = eventBroker
            .listen<void>(
                'productsLoaded',
                () => {
                    this.childrens = [];

                    $('#products-scroll-spy').children().each((index: any, value: any) => {
                        this.childrens.push(value);
                    });

                    if ($('.main-container')[0]) {
                        this.buildSection($('.main-container')[0].scrollTop - 150, $('.main-container')[0].offsetTop)
                    }
                }
            )
        ;
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        this.buildSection(event.target.scrollTop - 150, event.target.offsetTop)
    }

    buildSection(scrollTop: number, parentOffset: number): void {
        if (this.spiedTags.length === 0 ) {
            return;
        }

        let currentSection: string | undefined;

        this.eventBroker.emit<void>('resizeMenu', undefined);

        this.childrens.forEach((child: any) => {
            for (let j = 0; j < child.children.length; j++) {
                const element = child.children[j];
                if (element.classList.contains("childs")) {
                    //console.log(element);
                    //console.log('top:', element.getBoundingClientRect().top);
                    if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                        if ((element.offsetTop - parentOffset) <= scrollTop) {
                            currentSection = element.id;

                            this.currentSectionData = {
                                "sectionId": currentSection,
                                "categoryName": element.getAttribute('data-category-name'),
                                "categoryChildName": element.getAttribute('data-category-child-name'),
                                "categoryChildChildName": element.getAttribute('data-category-child-child-name')
                            }
                        }
                    }
                }

                for (let k = 0; k < element.children.length; k++) {
                    const elementChild = element.children[k];

                    if (elementChild.classList.contains("childs-childs")) {
                        if (this.spiedTags.some(spiedTag => spiedTag === elementChild.tagName)) {
                            if ((elementChild.offsetTop - parentOffset) <= scrollTop) {
                                currentSection = elementChild.id;

                                this.currentSectionData = {
                                    "sectionId": currentSection,
                                    "categoryName": elementChild.getAttribute('data-category-name'),
                                    "categoryChildName": elementChild.getAttribute('data-category-child-name'),
                                    "categoryChildChildName": elementChild.getAttribute('data-category-child-child-name')
                                }
                            }
                        }
                    }

                    for (let l = 0; l < elementChild.children.length; l++) {
                        const elementChildChild = elementChild.children[l];

                        if (elementChildChild.classList.contains("childs-childs-childs")) {
                            if (this.spiedTags.some(spiedTag => spiedTag === elementChildChild.tagName)) {
                                if ((elementChildChild.offsetTop - parentOffset) <= scrollTop) {
                                    currentSection = elementChildChild.id;

                                    this.currentSectionData = {
                                        "sectionId": currentSection,
                                        "categoryName": elementChildChild.getAttribute('data-category-name'),
                                        "categoryChildName": elementChildChild.getAttribute('data-category-child-name'),
                                        "categoryChildChildName": elementChildChild.getAttribute('data-category-child-child-name')
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });

        if (currentSection !== this.currentSection) {
            //console.log('Section change')
            this.currentSection = currentSection;
            //this.sectionChange.emit(this.currentSectionData);
            this.eventBroker.emit<{}>('sectionChanged', this.currentSectionData);
        }
    }

    ngOnDestroy() {
        this.loadedEventListener.ignore();
    }
}
