import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { NgClass } from "@angular/common";
import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { FritolayProductViewerComponent } from "@gc/fritolay-product-viewer";
import { NgxImageZoomComponent, NgxImageZoomModule } from "ngx-image-zoom";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defer,
  delay,
  expand,
  filter,
  interval,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeWhile,
  tap,
  timer,
} from "rxjs";
import { DevicesService } from "src/app/core/services/devices.service";
import { DownloadService } from "src/app/services/download.service";
import { MatDialogService } from "src/app/services/mat-dialog.service";
import { StronglyTypedMatDialog } from "src/app/shared/directives/strongly-typed-mat-dialog";
import { SharedService } from "src/app/shared/services/shared.service";
import { resizeObs } from "src/app/tools/rxjs.tools";
import { Product, ProductImage } from "src/app/types/product.type";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ProductVideoPlayerPopupComponent } from "../product-video-player-popup/product-video-player-popup.component";

export type ProductViewPopupData = {
  product: Product;
};

@Component({
  selector: "fc-product-view-popup",
  standalone: true,
  imports: [
    NgClass,
    NgxImageZoomModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FritolayProductViewerComponent,
    OverlayModule,
    ClipboardModule,
  ],
  templateUrl: "./product-view-popup.component.html",
  styleUrl: "./product-view-popup.component.scss",
})
export class ProductViewPopupComponent extends StronglyTypedMatDialog<
  ProductViewPopupData,
  void
> {
  sharedService = inject(SharedService);
  private _matDialogService = inject(MatDialogService);
  private _devicesService = inject(DevicesService);
  private _destroyRef = inject(DestroyRef);
  private _downloadService = inject(DownloadService);

  private readonly _ZOOM: number = 1.25;

  isMobile: boolean = this._devicesService.isMobile();

  product = this._data?.product;

  loadingImage: boolean = this.product ? this.product.images.length > 0 : false;

  currentProductImage?: ProductImage =
    this.product && this.product.images.length > 0
      ? this.product.images[0]
      : undefined;

  magnification = 1.5;

  display360 = false;
  showButton360: boolean = this.product?.properties.has360View ?? false;
  showVideoButton: boolean =
    (this.product?.properties.videos &&
      this.product.properties.videos.length > 0) ??
    false;
  showButtonLink: boolean =
    this.product?.properties.enableShareableLink ?? false;

  has360View: boolean = this._data?.product.properties.has360View ?? false;

  productViewerLink: string = `https://mcoe360.com/product-viewer/${this.product?.eqi}`;

  ePopValidUrl: boolean = false;

  @ViewChild("tooltip")
  private _tooltip?: MatTooltip;

  @ViewChild("matDialogContent", { static: true, read: ElementRef })
  private _matDialogContentRef!: ElementRef<HTMLElement>;

  @ViewChild("libNgxImageZoom")
  private set libNgxImageZoom(value: NgxImageZoomComponent | undefined) {
    this._libNgxImageZoomElement.next(value);
  }
  private _libNgxImageZoomElement = new BehaviorSubject<
    NgxImageZoomComponent | undefined
  >(undefined);

  private _fullImageSize$ = this._libNgxImageZoomElement.pipe(
    filter(
      (libNgxImageZoom): libNgxImageZoom is NgxImageZoomComponent =>
        !!libNgxImageZoom,
    ),
    //delay(0),
    switchMap((libNgxImageZoom) => {
      // Check every 500ms until image height is no longer 0
      // At which point it will emit that value and complete
      const imageSize$ = defer(() =>
        of({
          w: libNgxImageZoom?.fullWidth,
          h: libNgxImageZoom?.fullHeight,
        }),
      );
      return imageSize$.pipe(
        expand((size) => timer(500).pipe(switchMap(() => imageSize$))),
        takeWhile((size) => size.w <= 0 || size.h <= 0, true),
        filter((size) => size.w > 0 && size.h > 0),
      );
    }),
  );

  buttonLinkTooltipOn: boolean = false;

  buttonLinkTooltipPositions = [
    new ConnectionPositionPair(
      { originX: "center", originY: "top" },
      { overlayX: "center", overlayY: "bottom" },
    ),
  ];

  private _displayButtonLinkTooltip = new Subject<void>();

  ngOnInit(): void {
    if (this.sharedService.environmentIsPBNA()) {
      try {
        if (
          this.product?.properties.ePop &&
          this.product.properties.ePop.length > 0
        ) {
          this.ePopValidUrl = Boolean(new URL(this.product.properties.ePop));
        }
      } catch (e) {}
    }
    console.log(this.product);
    const contentContainerSize$ = resizeObs(
      this._matDialogContentRef.nativeElement,
    ).pipe(
      map(() => ({
        w: this._matDialogContentRef.nativeElement.offsetWidth,
        h: this._matDialogContentRef.nativeElement.offsetHeight,
      })),
    );
    combineLatest([this._fullImageSize$, contentContainerSize$])
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(([imageSize, containerSize]) => {
        const imageAR = imageSize.w / imageSize.h;
        const containerAR = containerSize.w / containerSize.h;
        if (imageAR >= containerAR) {
          this.magnification = (this._ZOOM * containerSize.h) / imageSize.h;
        } else {
          this.magnification = (this._ZOOM * containerSize.w) / imageSize.w;
        }
      });

    this._displayButtonLinkTooltip
      .pipe(
        switchMap(() => {
          this.buttonLinkTooltipOn = true;
          return timer(3000).pipe(
            map(() => {
              this.buttonLinkTooltipOn = false;
            }),
          );
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  downloadImages(): void {
    const imageIndex =
      this.product?.images.findIndex((im) => im === this.currentProductImage) ??
      -1;
    if (this.product && imageIndex >= 0) {
      this._matDialogService.createProductDownloadPopup(
        this.product,
        imageIndex,
      );
    }
  }

  changeProductImage(productImage: ProductImage): void {
    if (productImage !== this.currentProductImage) {
      this.loadingImage = true;
      this.currentProductImage = productImage;
    }
  }

  imageLoaded(): void {
    this.loadingImage = false;
  }

  toggle360(): void {
    this.display360 = !this.display360;
  }

  downloadingVideo: boolean = false;
  downloadVideo(): void {
    if (this.product?.properties.videos && !this.downloadingVideo) {
      this.downloadingVideo = true;
      this._downloadService
        .downloadFile(this.product.properties.videos[0])
        .pipe(
          catchError((e) => {
            console.error(e);
            return of();
          }),
          map(() => {
            this.downloadingVideo = false;
          }),
          takeUntilDestroyed(this._destroyRef),
        )
        .subscribe();
    }
  }

  displayButtonLinkTooltip(): void {
    this._displayButtonLinkTooltip.next();
  }

  playVideo(): void {
    this._matDialogService.open(ProductVideoPlayerPopupComponent, {
      data: {
        video: this.product?.properties.videos
          ? this.product?.properties.videos[0]
          : undefined,
      },
      minWidth: "80vw",
      width: "80vw",
      maxWidth: "unset",
      minHeight: "80vh",
      height: "80vh",
      maxHeight: "unset",
      disableClose: false,
    });
  }
}
