import { Component } from "@angular/core";
import { StronglyTypedMatDialog } from "src/app/shared/directives/strongly-typed-mat-dialog";

export type ProductVideoPlayerPopup = {
  video?: string;
};

@Component({
  selector: "fc-product-video-player-popup",
  standalone: true,
  imports: [],
  templateUrl: "./product-video-player-popup.component.html",
  styleUrl: "./product-video-player-popup.component.scss",
})
export class ProductVideoPlayerPopupComponent extends StronglyTypedMatDialog<
  ProductVideoPlayerPopup,
  void
> {
  video = this._data?.video;
}
