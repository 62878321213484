import { Component, OnInit, Input } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { DevicesService } from 'src/app/core/services/devices.service';
import { IBreadCrumb } from 'src/app/store/models/IBreadCrumb';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'fc-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbData?: IBreadCrumb;

  isMobile: boolean = false

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private devicesService: DevicesService) {
    this
      .store
      .select(state => state.breadCrumb)
      .subscribe((b: IBreadCrumb) => this.breadcrumbData = b)
    ;
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()
  }

  navigateToUrl(categoryData: any): void {
    this.sharedService.navigateToCategoryId(categoryData['id'])
  }
}
