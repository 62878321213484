<div class="tree-menu" [ngClass]="{ 'left-nav': isLeftNav }">
  <div class="content">
    @if (!isLeftNav) {
      <div class="actions">
        <button type="button" class="btn btn-1 menu-toggle">Done</button>
      </div>
      <div class="title-text">
        <div class="title">Menu</div>
      </div>
      <div class="mb-1">
        <div class="title-separator"></div>
      </div>
    }

    @if (categories().length > 0) {
      <ul
        class="tree-menu-nav list-group"
        [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
      >
        @if (sharedService.environmentIsMCOE()) {
          <li class="list-group-item chapter">
            <button
              class="btn p-0"
              (click)="navigateToBugName('Special Offers')"
            >
              <p class="ga-clic-bug-sidemenu" data-titlega="Special Offers">
                Special Offers
              </p>
            </button>
          </li>
        }
        <li class="list-group-item chapter">
          <button class="btn p-0" (click)="navigateToCategoryId()">
            <p class="ga-clic-product-sidemenu" data-titlega="Full Catalog">
              Full Catalog
            </p>
          </button>
        </li>

        @for (l1Category of categories(); track l1Category.id) {
          <div>
            <li class="list-group-item chapter">
              @if (l1Category.children.length > 0) {
                <a
                  data-toggle="collapse"
                  [href]="'#category2-' + l1Category.id"
                  aria-expanded="false"
                  class="collapsed"
                >
                  <span></span>
                </a>
              }
              <button
                class="btn p-0"
                (click)="navigateToCategoryId(l1Category.id)"
              >
                <p
                  class="ga-clic-product-sidemenu"
                  [attr.data-titlega]="l1Category.name"
                >
                  {{ l1Category.name }}
                </p>
              </button>
            </li>
            <div class="collapse" [id]="'category2-' + l1Category.id">
              @for (l2Category of l1Category.children; track l2Category.id) {
                <div>
                  <li class="list-group-item header">
                    @if (l2Category.children.length > 0) {
                      <a
                        data-toggle="collapse"
                        [href]="'#category3-' + l2Category.id"
                        aria-expanded="false"
                        class="collapsed"
                      >
                        <span></span>
                      </a>
                    }
                    <button
                      class="btn p-0"
                      (click)="navigateToCategoryId(l2Category.id)"
                    >
                      <p
                        class="ga-clic-product-sidemenu"
                        [attr.data-titlega]="l2Category.name"
                      >
                        {{ l2Category.name }}
                      </p>
                    </button>
                  </li>
                  <div class="collapse" [id]="'category3-' + l2Category.id">
                    @for (
                      l3Category of l2Category.children;
                      track l3Category.id
                    ) {
                      <div>
                        <li class="list-group-item subheader">
                          <button
                            class="btn p-0"
                            (click)="navigateToCategoryId(l3Category.id)"
                          >
                            <p
                              class="ga-clic-product-sidemenu"
                              [attr.data-titlega]="l3Category.name"
                            >
                              {{ l3Category.name }}
                            </p>
                          </button>
                        </li>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>

            @if (!isLeftNav) {
              <div class="separator"></div>
            }
          </div>
        }

        @if (sharedService.environmentIsFSV()) {
          <li class="list-group-item chapter">
            <button class="btn p-0" (click)="navigateToBugName('Accessories')">
              <p class="ga-clic-bug-sidemenu" data-titlega="Accessories">
                Accessories
              </p>
            </button>
          </li>
        }

        @if (!isLeftNav) {
          <li class="list-group-item chapter">
            <button class="btn p-0 inventory" (click)="displayInventoryModal()">
              @if (sharedService.environmentIsMCOE()) {
                <p>Inventory &<br />Sell Sheets</p>
              } @else if (sharedService.environmentIsFSV()) {
                <p>Reports, Forms<br />& Sell Sheets</p>
              }
            </button>
            <a
              class="btn-4 ga-clic-homepage switchDisplayStyleMobile"
              (click)="switchDisplayStyle()"
              data-titlega="Header - DisplayStyle"
            >
              @if (isCardLayout) {
                <img
                  [src]="
                    sharedService.environmentIsPBNA()
                      ? 'assets/images/products/CardViewOnPBNA.png'
                      : 'assets/images/products/CardViewOn.png'
                  "
                />
              } @else {
                <img
                  [src]="
                    sharedService.environmentIsPBNA()
                      ? 'assets/images/products/ListViewOnPBNA.png'
                      : 'assets/images/products/ListViewOn.png'
                  "
                />
              }
            </a>
          </li>

          <li class="list-group-item chapter">
            <button class="btn p-0 inventory" (click)="openEPOP()">
              <p>EPOP</p>
            </button>
          </li>
        }
      </ul>
    }
  </div>
</div>
