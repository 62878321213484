import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private deviceService: DeviceDetectorService) { }

  isDesktop() {
    return this.deviceService.isDesktop()
  }

  isMobile() {
    return !this.deviceService.isDesktop()
  }
}
