import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs";
import { SharedService } from "src/app/shared/services/shared.service";
import { CategoriesService } from "src/app/services/categories.service";
import { ProductsService } from "src/app/services/products.service";
import {
  SellsheetCategory,
  SellsheetTemplateToCPBNAComponent,
} from "./sellsheet-template-toc-pbna/sellsheet-template-toc-pbna.component";
import { SellsheetTemplatePBNAComponent } from "./sellsheet-template-pbna/sellsheet-template-pbna.component";
import { SellsheetTemplateComponent } from "./sellsheet-template/sellsheet-template.component";
import { CategoryWithProducts } from "src/app/types/category.type";
import { Product } from "src/app/types/product.type";

@Component({
  selector: "fc-sellsheet",
  templateUrl: "./sellsheet.component.html",
  styleUrls: ["./sellsheet.component.scss"],
  standalone: true,
  imports: [
    SellsheetTemplateComponent,
    SellsheetTemplateToCPBNAComponent,
    SellsheetTemplatePBNAComponent,
  ],
})
export class SellsheetComponent implements OnInit {
  sharedService = inject(SharedService);
  private _categoriesService = inject(CategoriesService);
  private _productsService = inject(ProductsService);
  private _activatedRoute = inject(ActivatedRoute);
  private _destroyRef = inject(DestroyRef);

  products: Product[] = [];
  categories: SellsheetCategory[] = [];
  offsetPage: number = 2;

  ngOnInit() {
    document.getElementById("wrapper")!.style.height = "unset";

    const objectIdParam =
      this._activatedRoute.snapshot.paramMap.get("objectId");
    const typeParam = this._activatedRoute.snapshot.paramMap.get("type");

    if (objectIdParam) {
      if (typeParam && typeParam === "product") {
        this.getProduct(parseInt(objectIdParam));
      } else {
        if (objectIdParam === "full") {
          this.getAllCategoriesWithProducts();
        } else {
          this.getCategoryWithProducts(parseInt(objectIdParam));
        }
      }
    }
  }

  private getProduct(productId: number): void {
    this._productsService
      .getProduct(productId)
      .pipe(
        map((product) => {
          this.products = [product];
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  private getCategoryWithProducts(categoryId: number): void {
    this._categoriesService
      .getCategoryWithProducts(categoryId)
      .pipe(
        map((category) => {
          // Sort products
          const sortedL2Categories = category.children.map((l2Category) => {
            const sortedl3Categories = l2Category.children.map((l3Category) => {
              return {
                ...l3Category,
                products: [...l3Category.products].sort(
                  (a, b) => a.sortId - b.sortId,
                ),
              };
            });

            return {
              ...l2Category,
              children: sortedl3Categories,
              products: [...l2Category.products].sort(
                (a, b) => a.sortId - b.sortId,
              ),
            };
          });

          let products: Product[] = [];
          sortedL2Categories.forEach((l2Category) => {
            if (l2Category.products.length > 0) {
              products.push(...l2Category.products);
            }

            l2Category.children.forEach((l3Category) => {
              if (l3Category.products.length > 0) {
                products.push(...l3Category.products);
              }
            });
          });

          products = products.filter((p) => p.hasSellSheet);

          const productsIds = products.map((p) => p.id);
          this.products = products.filter(
            ({ id }, index) => !productsIds.includes(id, index + 1),
          );
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  private getAllCategoriesWithProducts(): void {
    this._categoriesService
      .getCategoriesWithProducts()
      .pipe(
        map((categories) => {
          if (this.sharedService.environmentIsPBNA()) {
            // Merge all Flex products into one subcategory (and Electric Barrel)
            // Also merge all subcategories into 1 category and sort them
            let mainCategory: CategoryWithProducts = {
              id: 0,
              name: "main",
              displayOrder: 0,
              products: [],
              children: [],
            };

            categories.forEach((l1Category) => {
              if (
                l1Category.name === "Flex" ||
                l1Category.name === "Electric Barrel"
              ) {
                if (l1Category.children.length > 0) {
                  const products = l1Category.children.reduce<Product[]>(
                    (acc, c) => {
                      if (c.products.length > 0) {
                        acc.push(...c.products);
                      }
                      return acc;
                    },
                    [],
                  );
                  l1Category = {
                    ...l1Category,
                    children: [
                      {
                        id: l1Category.children[0].id,
                        name: l1Category.name,
                        displayOrder: l1Category.displayOrder,
                        products: products,
                        children: [],
                      },
                    ],
                  };
                }
              }

              mainCategory = {
                ...mainCategory,
                children: [...mainCategory.children, ...l1Category.children],
              };

              mainCategory = {
                ...mainCategory,
                children: [...mainCategory.children].sort((a, b) =>
                  a.name > b.name ? 1 : -1,
                ),
              };
            });
            categories = [mainCategory];
          }

          // Sort products
          categories = categories.map((l1Category) => {
            const sortedL2Categories = l1Category.children.map((l2Category) => {
              const sortedl3Categories = l2Category.children.map(
                (l3Category) => {
                  return {
                    ...l3Category,
                    products: [...l3Category.products].sort(
                      (a, b) => a.sortId - b.sortId,
                    ),
                  };
                },
              );

              return {
                ...l2Category,
                children: sortedl3Categories,
                products: [...l2Category.products].sort(
                  (a, b) => a.sortId - b.sortId,
                ),
              };
            });

            return { ...l1Category, children: sortedL2Categories };
          });

          // Build products list and sellsheet categories list
          let products: Product[] = [];
          const sellsheetCategories: SellsheetCategory[] = [];
          categories.forEach((l1Category) => {
            l1Category.children.forEach((l2Category) => {
              if (l2Category.products.length > 0) {
                products.push(...l2Category.products);

                if (l2Category.products.some((p) => p.hasSellSheet)) {
                  sellsheetCategories.push({
                    name: l2Category.name,
                    fragment: l2Category.products[0].id.toString(),
                  });
                }
              }

              l2Category.children.forEach((l3Category) => {
                if (l3Category.products.length > 0) {
                  products.push(...l3Category.products);
                  if (l3Category.products.some((p) => p.hasSellSheet)) {
                    sellsheetCategories.push({
                      name: l3Category.name,
                      fragment: l3Category.products[0].id.toString(),
                    });
                  }
                }
              });
            });
          });

          products = products.filter((p) => p.hasSellSheet);

          const productsIds = products.map((p) => p.id);
          this.products = products.filter(
            ({ id }, index) => !productsIds.includes(id, index + 1),
          );
          this.categories = sellsheetCategories;
          this.offsetPage = 2 + Math.floor(this.categories.length / 20);
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }
}
