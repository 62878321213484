import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainLoginService {

  private _loggin = new BehaviorSubject<boolean>(false);
  get login$(): Observable<boolean> { return this._loggin.asObservable(); }

  private static readonly LOGIN_KEY: string = 'main-login';

  isLoggedIn(): boolean {
    return sessionStorage.getItem(MainLoginService.LOGIN_KEY) === 'true';
  }

  login(password: string): Observable<boolean> {
    if (password === 'pepsi.pme') {
      sessionStorage.setItem(MainLoginService.LOGIN_KEY, 'true');
      this._loggin.next(true);
    }
    return of(this.isLoggedIn());
  }

  constructor() { }
}
