import { delay, Observable } from "rxjs";

export function resizeObs(
  element: HTMLElement,
  skipDelay: boolean = false,
): Observable<void> {
  const resizeObs$ = new Observable<void>((subscriber) => {
    const resizeObserver = new ResizeObserver(() => subscriber.next(undefined));
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  });

  return skipDelay ? resizeObs$ : resizeObs$.pipe(delay(0));
}
