import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment'

import { SearchResult } from 'src/app/public/interfaces/search-result'

import { Observable, throwError } from 'rxjs';
import { debounceTime, filter, switchMap, retry, catchError, tap } from 'rxjs/operators';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient) { }

  search(searchTerm: Observable<string>) {
    return searchTerm.pipe(
      tap(x => {
        $(".search-results-list").css('display', 'none')
      }),
      debounceTime(1000),
      filter((searchTerm: string) => searchTerm !== undefined && searchTerm.length > 2),
      switchMap(searchTerm => this.searchTerm(searchTerm))
    )
  }

  searchTerm(searchTerm: string) {
    $(".search-button-image").css('display', 'none')
    $(".search-spinner").css('display', 'flex')

    return this.http.get<SearchResult[]>(environment.endPoint + '/search/completion?k=' + searchTerm)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      )
  }

  private errorHandler(error: any) {
    console.error('there was an error sending the query', error)

    $(".search-button-image").css('display', 'block')
    $(".search-spinner").css('display', 'none')
    $(".search-results-list").css('display', 'none')

    return throwError(error);
  }
}
