import { importProvidersFrom } from "@angular/core";
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from "@angular/common/http";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideRouter, withInMemoryScrolling } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { AppComponent } from "./app/app.component";
import { reducers, metaReducers } from "./app/store/state/app.state";
import { GraphQLModule } from "./app/core/graphql.module";
import { APP_ROUTES } from "./app/app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      APP_ROUTES,
      withInMemoryScrolling({ anchorScrolling: "enabled" }),
    ),
    importProvidersFrom(
      BrowserModule,
      StoreModule.forRoot(reducers, { metaReducers }),
      GraphQLModule,
    ),
    provideAnimationsAsync(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true },
    },
  ],
}).catch((err) => console.error(err));
