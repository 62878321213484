import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  Signal,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { map, Observable } from "rxjs";
import { Apollo, gql, QueryRef } from "apollo-angular";
import {
  AddSettingInput,
  Setting as SettingGQL,
  UpdateSettingInput as UpdateSettingInputGQL,
} from "../types/graphql/graphql.type";
import { Setting } from "../types/setting.type";

export type CreateSettingInput = AddSettingInput;
export type UpdateSettingInput = UpdateSettingInputGQL;

type SettingsResponse = {
  settings: SettingGQL[];
};

type CreateSettingResponse = {
  createSetting: SettingGQL;
};

type CreateSettingVariables = {
  setting: AddSettingInput;
};

type UpdateSettingResponse = {
  updateSetting: SettingGQL;
};

type UpdateSettingVariables = {
  setting: UpdateSettingInputGQL;
};

type DeleteSettingResponse = {
  deleteSetting: boolean;
};

type DeleteSettingVariables = {
  settingId: number;
};

export const SETTING_FIELDS = gql`
  fragment SettingFields on Setting {
    id
    key
    value
    displayName
  }
`;

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private _apollo = inject(Apollo);
  private _destroyRef = inject(DestroyRef);

  private _GET_SETTINGS_QUERY = gql`
    query Settings {
      settings {
        ...SettingFields
      }
    }
  `;

  private _settings = signal<Setting[]>([]);
  get settings(): Signal<Setting[]> {
    this.fetchSettings();
    return this._settings.asReadonly();
  }

  settingsKeyMap = computed(() => {
    // use type "Setting | undefined" here to get undefined option when accessing with brackets
    return this.settings().reduce<{ [key: string]: Setting | undefined }>(
      (settings, s) => {
        settings[s.key] = s;
        return settings;
      },
      {},
    );
  });

  private _loaded = signal<boolean>(false);
  loaded = this._loaded.asReadonly();

  private _fetched: boolean = false;
  private _settingsQueryRef?: QueryRef<SettingsResponse>;

  private fetchSettings(): void {
    if (this._fetched) {
      return;
    }

    this._fetched = true;
    this._settingsQueryRef = this._apollo.watchQuery<SettingsResponse>({
      query: this._GET_SETTINGS_QUERY,
    });
    this._settingsQueryRef.valueChanges
      .pipe(
        map(({ data: { settings } }) => settings),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((settings) => {
        this._settings.set(settings);
        this._loaded.set(true);
      });
  }

  refetchSettings(): void {
    if (this._settingsQueryRef) {
      this._settingsQueryRef.refetch();
    } else {
      this.fetchSettings();
    }
  }

  createSetting(settingData: CreateSettingInput): Observable<void> {
    return this._apollo
      .mutate<CreateSettingResponse, CreateSettingVariables>({
        mutation: gql`
          mutation CreateSetting($setting: AddSettingInput!) {
            createSetting(setting: $setting) {
              ...SettingFields
            }
          }
        `,
        variables: { setting: settingData },
        update: (cache, { data }) => {
          const existingData = cache.readQuery<SettingsResponse>({
            query: this._GET_SETTINGS_QUERY,
          });
          if (existingData && data?.createSetting) {
            cache.writeQuery({
              query: this._GET_SETTINGS_QUERY,
              data: { settings: [...existingData.settings, data.createSetting] },
            });
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateSetting(settingData: UpdateSettingInput): Observable<void> {
    return this._apollo
      .mutate<UpdateSettingResponse, UpdateSettingVariables>({
        mutation: gql`
          mutation UpdateSetting($setting: UpdateSettingInput!) {
            updateSetting(setting: $setting) {
              ...SettingFields
            }
          }
        `,
        variables: { setting: settingData },
      })
      .pipe(map(({ data }) => {}));
  }

  deleteSetting(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteSettingResponse, DeleteSettingVariables>({
        mutation: gql`
          mutation DeleteSetting($settingId: Int!) {
            deleteSetting(settingId: $settingId)
          }
        `,
        variables: { settingId: id },
        update: (cache, { data }) => {
          if (data?.deleteSetting) {
            const identity = cache.identify({ id, __typename: "Setting" });
            cache.evict({ id: identity, broadcast: true });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteSetting) {
            throw "Error deleting Setting";
          }
        }),
      );
  }
}
