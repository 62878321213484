@if (customPopup()) {
  <div
    class="custom-modal modal fade"
    [id]="popupKey"
    tabindex="-1"
    role="dialog"
    aria-labelledby="customModal"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
      <div class="modal-content">
        @if (customPopup()?.properties?.title) {
          <div class="modal-header">
            <h5 class="modal-title">{{ customPopup()!.properties.title }}</h5>
          </div>
        }
        <div class="modal-body">
          <div id="custom-modal-content">
            @if (customPopup()?.properties?.imageName) {
              <img
                [src]="customPopup()!.properties.imageName"
                (click)="imgClicked(customPopup()!.properties.actionUrl)"
              />
            }
          </div>
        </div>
        @if (customPopup()?.properties?.showCloseButton) {
          <div class="modal-footer">
            <button type="button" class="btn btn-1" (click)="closeModal()">
              Close
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
