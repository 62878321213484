import { Routes } from '@angular/router';
import { AuthComponent } from './public/auth/auth/auth.component';
import { HomeComponent } from './public/home/home.component';
import { ProductComponent } from './public/product/product.component';
import { PrintComponent } from './public/print/print.component';
import { PdfComponent } from './public/pdf/pdf.component';
import { SellsheetComponent } from './public/sellsheet/sellsheet.component';
import { LoginComponent } from './public/login/login.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { MainLoginComponent } from './public/main-login/main-login.component';
import { mainLoginGuard } from './public/main-login/main-login.guard';
import { authGuardRestrict } from './public/auth/auth-guard-restrict.service';
import { environment } from 'src/environments/environment';

export const APP_ROUTES: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [mainLoginGuard, authGuardRestrict],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [mainLoginGuard],
  },
  {
    path: 'products/:type/:objectId',
    component: ProductComponent,
    canActivate: [mainLoginGuard],
  },
  {
    path: 'print/:fullCatalog/:categoriesIds/:bugsIds',
    component: PrintComponent,
  },
  {
    path: 'pdf/:pdfName',
    component: PdfComponent,
    canActivate: [mainLoginGuard],
  },
  {
    path: 'sellsheet/:objectId',
    component: SellsheetComponent,
  },
  {
    path: 'sellsheet/:objectId/:type',
    component: SellsheetComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [mainLoginGuard, authGuardRestrict],
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' },
];

if (environment.environmentId === 'pbna') {
  APP_ROUTES.splice(0, 0, {
    path: 'main-login',
    component: MainLoginComponent,
    canActivate: [],
  });
}
