import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  inject,
  effect,
} from "@angular/core";
import { NgClass } from "@angular/common";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/shared/services/shared.service";
import { EventBrokerService } from "src/app/shared/services/eventBroker.service";
import { AuthService } from "src/app/public/auth/auth.service";
import { CategoriesService } from "src/app/services/categories.service";
import { AppState } from "src/app/store/state/app.state";
import {
  DisplayStyle,
  ISiteSettings,
} from "src/app/store/models/ISiteSettings";
import { ClientFunctions } from "src/app/shared/interfaces/client-functions";
import { setSiteSettingsDisplayStyleAction } from "src/app/store/actions/SiteSettings.actions";

declare var dropdownHoverIntent: any;
declare var $: any;

@Component({
  selector: "fc-menu-desktop",
  templateUrl: "./menu.desktop.component.html",
  styleUrls: ["./menu.desktop.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class MenuDesktopComponent implements OnInit, OnDestroy {
  @Input() isFsvSecureAccess?: boolean;

  private _categoriesService = inject(CategoriesService);

  categories = this._categoriesService.categories;

  private desktopMenuSubjectSubscription?: Subscription;
  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  clientFunctions: ClientFunctions = {
    mcoe: {},
    fsv: {},
    pbna: {},
  };

  private _dropdownCreated: boolean = false;

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private eventBroker: EventBrokerService,
    public authService: AuthService,
  ) {
    this.store
      .select((state) => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.displayStyle = s ? s.displayStyle : DisplayStyle.Cards;
        this.isCardLayout = this.displayStyle === DisplayStyle.Cards;
      });

    effect(() => {
      if (!this._dropdownCreated && this.categories().length > 0) {
        this._dropdownCreated = true;
        new dropdownHoverIntent();
      }
    });
  }

  ngOnInit() {
    this.clientFunctions = {
      mcoe: {
        inventoryClicked: () => {
          this.displayInventoryModal();
        },
        epopClicked: () => {
          window.open("https://mcoe.mypepsico.com/", "_blank");
        },
      },
      fsv: {
        epopClicked: () => {
          window.open(
            "https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx",
            "_blank",
          );
        },
      },
      pbna: {
        inventoryClicked: () => {},
        epopClicked: () => {
          window.open("https://epop.imsfastpak.com/default.aspx", "_blank");
        },
      },
    };
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.sharedService.environmentIsFSV()) {
      this.desktopMenuSubjectSubscription?.unsubscribe();
    }
  }

  navigateToCategoryId(categoryId?: number) {
    this.sharedService.navigateToCategoryId(categoryId ?? -1);
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName);
  }

  displayInventoryModal() {
    $("#black-overlay").addClass("visible");
    $("#inventory-modal").modal("show");
  }

  switchDisplayStyle() {
    this.store.dispatch(
      setSiteSettingsDisplayStyleAction({
        displayStyle:
          this.displayStyle === DisplayStyle.Cards
            ? DisplayStyle.Rows
            : DisplayStyle.Cards,
      }),
    );

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>("productsLoaded", undefined);
    }, 500);
  }

  openSellSheets() {
    window.open(
      "https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui",
      "_blank",
    );
  }
}
