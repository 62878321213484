import { Component, OnInit } from '@angular/core';
import { ProductComponent } from '../product/product.component';

@Component({
    selector: 'fc-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
    standalone: true,
    imports: [ProductComponent]
})
export class PrintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
