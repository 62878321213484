import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainLoginService } from './main-login.service';

export const mainLoginGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  if (environment.environmentId !== 'pbna') {
    return true;
  }

  const mainLoginService = inject(MainLoginService);
  const router = inject(Router);
  return mainLoginService.isLoggedIn() ? true : router.parseUrl('main-login');
};
