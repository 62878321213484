import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from "src/app/public/auth/auth.service";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'fc-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule]
})
export class AuthComponent implements OnInit {
  message: string = ""
  password?: string

  constructor(
    private authService: AuthService, 
    private router: Router
  ) { }

  ngOnInit() {
  }

  validateForm(): void {
    let forms = document.getElementsByClassName('needs-validation');

    Array.prototype.filter.call(forms, (form) => {
      if (form.checkValidity() === false) {
        event?.preventDefault();
        event?.stopPropagation();
      } else {
        this.login()
      }

      form.classList.add('was-validated');
    });
  }

  private login(): void {
    this.authService.login(this.password ?? '').subscribe(() => {
      if (this.authService.isFsvSecureAccess()) {
        this.message = ""
        this.router.navigate(['/']);
      } else {
        this.message = "Wrong password. Please try again!"
      }
    });
  }
}
