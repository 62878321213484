@if (!sharedService.environmentIsPBNA()) {
  @for (product of products; track product.id) {
    <fc-sellsheet-template [product]="product"> </fc-sellsheet-template>
  }
} @else {
  <fc-sellsheet-template-toc-pbna
    [categories]="categories"
  ></fc-sellsheet-template-toc-pbna>
  @for (product of products; track product.id) {
    <fc-sellsheet-template-pbna
      [id]="product.id"
      [product]="product"
      [page]="$index + offsetPage"
    >
    </fc-sellsheet-template-pbna>
  }
}
