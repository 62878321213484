import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BugsService } from "src/app/services/bugs.service";
import { CategoriesService } from "src/app/services/categories.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private _router = inject(Router);
  private _categoriesService = inject(CategoriesService);
  private _bugsService = inject(BugsService);

  public canAccessBitBetsUrl = false;

  enableAccessBitBetsUrl(): void {
    this.canAccessBitBetsUrl = true;
  }
  disableAccessBitBetsUrl(): void {
    this.canAccessBitBetsUrl = false;
  }

  navigateToCategoryName(categoryName: string) {
    const category = this._categoriesService
      .flattenedCategories()
      .find((c) => c.name === categoryName);

    if (category?.id) {
      this._router.navigateByUrl("products/categories/" + category.id);
      window.scrollTo(0, 0);
    }
  }

  navigateToCategoryId(categoryId: number) {
    if (categoryId === -1) {
      this._router.navigateByUrl("products/categories/all");
    } else {
      this._router.navigateByUrl("products/categories/" + categoryId);
    }

    window.scrollTo(0, 0);
  }

  navigateToBugName(bugName: string) {
    const bug = this._bugsService.bugs().find((b) => b.name === bugName);
    if (bug?.id) {
      this._router.navigateByUrl("products/bugs/" + bug.id);
      window.scrollTo(0, 0);
    }
  }

  navigateToBugId(bugId: number) {
    if (bugId) {
      this._router.navigateByUrl("products/bugs/" + bugId);
      window.scrollTo(0, 0);
    }
  }

  navigateToProductId(productId: number) {
    if (productId) {
      this._router.navigateByUrl("products/product/" + productId);
      window.scrollTo(0, 0);
    }
  }

  navigateToSearchByKeyword(keyword: string) {
    this._router.navigateByUrl(
      "products/search/" + encodeURIComponent(keyword),
    );
  }

  navigateToColorId(colorId: number) {
    if (colorId) {
      this._router.navigateByUrl("products/colors/" + colorId);
      window.scrollTo(0, 0);
    }
  }

  navigateToPdf(pdfName: string) {
    this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this._router.navigateByUrl("pdf/" + pdfName);
      window.scrollTo(0, 0);
    });
  }

  navigateToUrl(url: string) {
    this._router.navigateByUrl(url);
  }

  browserIsIE() {
    return (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > -1
    );
  }

  getEnvironmentId() {
    return environment.environmentId;
  }

  getEnvironmentContainer() {
    return environment.container;
  }

  environmentIsMCOE() {
    return this.getEnvironmentId() === "mcoe";
  }

  environmentIsFSV() {
    return this.getEnvironmentId() === "fsv";
  }

  environmentIsPBNA() {
    return this.getEnvironmentId() === "pbna";
  }
}
