import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgFor } from '@angular/common';


export type SellsheetCategory = {
  fragment: string;
  name: string;
}


type Item = SellsheetCategory & {
  row: number;
  col: number;
  index: string;
}

type PageItems = {
  items: Item[];
}


@Component({
    selector: 'fc-sellsheet-template-toc-pbna',
    templateUrl: './sellsheet-template-toc-pbna.component.html',
    styleUrls: ['./sellsheet-template-toc-pbna.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class SellsheetTemplateToCPBNAComponent implements OnInit {

  @Input() set categories(value: SellsheetCategory[]) {
    this._categories = value;

    let pageItems: PageItems[] = [];
    let i = 0;
    while (i < value.length) {
      const l = Math.min(value.length - i, 20);
      pageItems.push({
        items: value.slice(i, i + l).map((v, j) => ({
          ...v,
          row: j % 5 + 1,
          col: Math.floor(j / 5) + 1,
          index: (i + j + 1).toString().padStart(2, '0'),
        }))
      });
      i += l;
    }
    this.pageItems = pageItems;
  }
  get categories(): SellsheetCategory[] { return this._categories; }
  private _categories: SellsheetCategory[] = [];

  pageItems: PageItems[] = [];


  constructor(
    public sharedService: SharedService,
  ) { }

  ngOnInit() { }
}
