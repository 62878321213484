@if (data?.title) {
  <h1 mat-dialog-title>{{ data?.title }}</h1>
}

@if (data?.text) {
  <div mat-dialog-content>
    <p>{{ data?.text }}</p>
  </div>
}
<div mat-dialog-actions [align]="'center'">
  <button
    mat-flat-button
    mat-dialog-close
    [ngClass]="data?.buttonClass"
    cdkFocusInitial
  >
    {{ data?.buttonText ?? "OK" }}
  </button>
</div>
