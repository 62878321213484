import { inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChildFn,
} from '@angular/router';

import { AuthServiceAdmin } from 'src/app/admin/auth.service';

export const authGuardRestrict: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);

  if (next.routeConfig?.path === 'login') {
    const authServiceAdmin = inject(AuthServiceAdmin);
    if (!authServiceAdmin.userIsLoggedIn()) {
      return true;
    } else {
      return router.createUrlTree(['/admin/dashboard']);
    }
  }
  return router.createUrlTree(['/']);
};
