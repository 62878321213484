import { NgClass } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {
  catchError,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";
import { ajax } from "rxjs/ajax";
import { DownloadService } from "src/app/services/download.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { StronglyTypedMatDialog } from "src/app/shared/directives/strongly-typed-mat-dialog";
import { extractFileBasename } from "src/app/tools/file.tools";
import { Product } from "src/app/types/product.type";
import { environment } from "src/environments/environment";

export type ProductDownloadPopupData = {
  product: Product;
  imageIndex?: number;
};

@Component({
  selector: "fc-product-download-popup",
  standalone: true,
  imports: [
    NgClass,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: "./product-download-popup.component.html",
  styleUrl: "./product-download-popup.component.scss",
})
export class ProductDownloadPopupComponent extends StronglyTypedMatDialog<
  ProductDownloadPopupData,
  void
> {
  sharedService = inject(SharedService);
  private _httpClient = inject(HttpClient);
  private _downloadService = inject(DownloadService);
  private _destroyRef = inject(DestroyRef);

  product = this._data?.product;

  image = this.product?.images[this._data?.imageIndex ?? 0];

  zipAvailable = toSignal(this.checkZipFile(), { initialValue: false });

  downloading: boolean = false;

  eqiText =
    !this.sharedService.environmentIsMCOE() ||
    !this.product?.eqi.startsWith("900")
      ? this.product?.eqi
      : undefined;

  downloadImage(): void {
    if (!this.image || !this.product) {
      return;
    }

    const product = this.product;
    const image = this.image;

    const imageBasename = extractFileBasename(image.image);
    const imgSrcWithoutExtension = `${environment.container}/images-products/originals/${imageBasename}`;

    this._httpClient.head(`${imgSrcWithoutExtension}.jpg`);

    const checkPng$ = this._httpClient
      .head(`${imgSrcWithoutExtension}.png`, { observe: "response" })
      .pipe(catchError(() => of(null)));
    const checkJpg$ = this._httpClient
      .head(`${imgSrcWithoutExtension}.jpg`, { observe: "response" })
      .pipe(catchError(() => of(null)));

    this.downloading = true;
    forkJoin([checkPng$, checkJpg$])
      .pipe(
        switchMap(([pngResult, jpgResult]) => {
          const r = [];
          if (pngResult) {
            const lastModified = pngResult.headers.get("Last-Modified");
            r.push({
              response: pngResult,
              type: "png",
              lastModified: lastModified ? new Date(lastModified) : new Date(),
            });
          }

          if (jpgResult) {
            const lastModified = jpgResult.headers.get("Last-Modified");
            r.push({
              response: jpgResult,
              type: "jpg",
              lastModified: lastModified ? new Date(lastModified) : new Date(),
            });
          }

          if (r.length === 0) {
            console.warn(
              `No original images found for: ${product.id} - ${product.name} - ${product.eqi}`,
            );
            return of(null);
          }

          const latest = r.sort(
            (a, b) => b.lastModified.getTime() - a.lastModified.getTime(),
          )[0];

          const imageIndex = product.images.findIndex((im) => im === image);
          const downloadName = this.sharedService.environmentIsPBNA()
            ? `${product.eqi}-${imageIndex + 1}_${product.name}.${latest.type}`
            : `${product.colors.length > 0 && product.colors[0].fullEqi ? product.colors[0].fullEqi : product.eqi}_${product.name}.${latest.type}`;
          return this._downloadService.downloadFile(
            latest.response.url!,
            downloadName,
          );
        }),
        finalize(() => {
          this.downloading = false;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  downloadZip(): void {
    window.open(this.computeZipUrl(), "_blank");
  }

  close(): void {
    this._matDialogRef.close();
  }

  checkZipFile(): Observable<boolean> {
    return ajax({
      url: this.computeZipUrl(),
      method: "HEAD",
    }).pipe(
      map((v) => v.status === 200),
      catchError(() => of(false)),
    );
  }

  private computeZipUrl(): string {
    let zipFile: string = "";
    if (this.product) {
      if (this.product.colors.length && this.product.colors[0].fullEqi) {
        zipFile = this.product.colors[0].fullEqi;
      } else {
        zipFile = this.product.eqi;
      }
    }
    return `${environment.container}/images-products/alt/${zipFile}.zip`;
  }
}
