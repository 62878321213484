import { DestroyRef, inject, Injectable, Signal, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { map, Observable } from "rxjs";
import { Apollo, gql } from "apollo-angular";
import {
  AddFrontpageSectionInput,
  AddFrontpageSectionItemInput,
  FrontpageSection as FrontpageSectionGQL,
  FrontpageSectionItem as FrontpageSectionItemGQL,
  UpdateFrontpageSectionInput as UpdateFrontpageSectionInputGQL,
  UpdateFrontpageSectionItemInput as UpdateFrontpageSectionItemInputGQL,
} from "../types/graphql/graphql.type";
import { FrontpageSection } from "../types/frontpage-section.type";
import { environment } from "src/environments/environment";
import { extractFilename } from "../tools/file.tools";
import { UploadService } from "./upload.service";
import { UploadRef } from "../types/upload";

export type CreateSectionInput = AddFrontpageSectionInput;
export type UpdateSectionInput = UpdateFrontpageSectionInputGQL;
export type CreateSectionItemInput = AddFrontpageSectionItemInput;
export type UpdateSectionItemInput = UpdateFrontpageSectionItemInputGQL;

type FrontpageSectionsResponse = {
  frontpageSections: FrontpageSectionGQL[];
};

type CreateFrontpageSectionResponse = {
  createSection: FrontpageSectionGQL;
};

type CreateFrontpageSectionVariables = {
  section: AddFrontpageSectionInput;
};

type UpdateFrontpageSectionResponse = {
  updateSection: FrontpageSectionGQL;
};

type UpdateFrontpageSectionVariables = {
  section: UpdateFrontpageSectionInputGQL;
};

type DeleteFrontpageSectionResponse = {
  deleteSection: boolean;
};

type DeleteFrontpageSectionVariables = {
  sectionId: number;
};

type CreateFrontpageSectionItemResponse = {
  createSectionItem: FrontpageSectionItemGQL;
};

type CreateFrontpageSectionItemVariables = {
  item: AddFrontpageSectionItemInput;
};

type UpdateFrontpageSectionItemResponse = {
  updateSectionItem: FrontpageSectionItemGQL;
};

type UpdateFrontpageSectionItemVariables = {
  item: UpdateFrontpageSectionItemInputGQL;
};

type DeleteFrontpageSectionItemResponse = {
  deleteSectionItem: boolean;
};

type DeleteFrontpageSectionItemVariables = {
  itemId: number;
};

export const FRONTPAGE_SECTION_ITEM_FIELDS = gql`
  fragment FrontpageSectionItemFields on FrontpageSectionItem {
    id
    name
    displayOrder
    imageUrl
    targetUrl
    badgeKey
  }
`;

export const FRONTPAGE_SECTION_FIELDS = gql`
  fragment FrontpageSectionFields on FrontpageSection {
    id
    name
    displayOrder
    showBackground
    items {
      ...FrontpageSectionItemFields
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FrontpageSectionService {
  private _apollo = inject(Apollo);
  private _destroyRef = inject(DestroyRef);
  private _uploadService = inject(UploadService);

  private _GET_FRONTPAGE_SECTIONS_QUERY = gql`
    query FrontpageSections {
      frontpageSections {
        ...FrontpageSectionFields
      }
    }
  `;

  readonly SECTION_ITEM_IMAGE_FOLDER: string = "homepageCategories";

  private _sections = signal<FrontpageSection[]>([]);
  get sections(): Signal<FrontpageSection[]> {
    this.fetchFrontpageSections();
    return this._sections.asReadonly();
  }

  private _loaded = signal<boolean>(false);
  loaded = this._loaded.asReadonly();

  private _fetched: boolean = false;

  private fetchFrontpageSections(): void {
    if (this._fetched) {
      return;
    }

    this._fetched = true;
    this._apollo
      .watchQuery<FrontpageSectionsResponse>({
        query: this._GET_FRONTPAGE_SECTIONS_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { frontpageSections } }) =>
          frontpageSections.map((s) =>
            this.frontpageSectionGQLtoFrontpageSection(s),
          ),
        ),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((frontpageSections) => {
        this._sections.set(frontpageSections);
        this._loaded.set(true);
      });
  }

  private frontpageSectionGQLtoFrontpageSection(
    section: FrontpageSectionGQL,
  ): FrontpageSection {
    return {
      id: section.id,
      name: section.name,
      displayOrder: section.displayOrder,
      showBackground: section.showBackground,
      items:
        section.items?.map((item) => ({
          id: item.id,
          name: item.name,
          displayOrder: item.displayOrder,
          imageUrl: `${environment.container}/assets/homepageCategories/${item.imageUrl}`,
          targetUrl: item.targetUrl,
          badgeKey: item.badgeKey ?? undefined,
        })) ?? [],
    };
  }

  createSection(sectionData: CreateSectionInput): Observable<void> {
    return this._apollo
      .mutate<CreateFrontpageSectionResponse, CreateFrontpageSectionVariables>({
        mutation: gql`
          mutation CreateSection($section: AddFrontpageSectionInput!) {
            createSection(section: $section) {
              ...FrontpageSectionFields
            }
          }
        `,
        variables: { section: sectionData },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(map(({ data }) => {}));
  }

  updateSection(sectionData: UpdateSectionInput): Observable<void> {
    return this._apollo
      .mutate<UpdateFrontpageSectionResponse, UpdateFrontpageSectionVariables>({
        mutation: gql`
          mutation UpdateSection($section: UpdateFrontpageSectionInput!) {
            updateSection(section: $section) {
              ...FrontpageSectionFields
            }
          }
        `,
        variables: { section: sectionData },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(map(({ data }) => {}));
  }

  deleteSection(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteFrontpageSectionResponse, DeleteFrontpageSectionVariables>({
        mutation: gql`
          mutation DeleteSection($sectionId: Int!) {
            deleteSection(sectionId: $sectionId)
          }
        `,
        variables: { sectionId: id },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteSection) {
            throw "Error deleting Section";
          }
        }),
      );
  }

  uploadSectionItemImage(file: File): UploadRef {
    return this._uploadService.uploadAsset(
      file,
      this.SECTION_ITEM_IMAGE_FOLDER,
    );
  }

  createSectionItem(sectionItemData: CreateSectionItemInput): Observable<void> {
    sectionItemData = {
      ...sectionItemData,
      imageUrl: this.sanitizeFilename(sectionItemData.imageUrl),
    };
    return this._apollo
      .mutate<
        CreateFrontpageSectionItemResponse,
        CreateFrontpageSectionItemVariables
      >({
        mutation: gql`
          mutation CreateSectionItem($item: AddFrontpageSectionItemInput!) {
            createSectionItem(item: $item) {
              ...FrontpageSectionItemFields
            }
          }
        `,
        variables: { item: sectionItemData },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(map(({ data }) => {}));
  }

  updateSectionItem(sectionItemData: UpdateSectionItemInput): Observable<void> {
    sectionItemData = {
      ...sectionItemData,
      imageUrl: sectionItemData.imageUrl
        ? this.sanitizeFilename(sectionItemData.imageUrl)
        : undefined,
    };
    return this._apollo
      .mutate<
        UpdateFrontpageSectionItemResponse,
        UpdateFrontpageSectionItemVariables
      >({
        mutation: gql`
          mutation UpdateSectionItem($item: UpdateFrontpageSectionItemInput!) {
            updateSectionItem(item: $item) {
              ...FrontpageSectionItemFields
            }
          }
        `,
        variables: { item: sectionItemData },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(map(({ data }) => {}));
  }

  deleteSectionItem(id: number): Observable<void> {
    return this._apollo
      .mutate<
        DeleteFrontpageSectionItemResponse,
        DeleteFrontpageSectionItemVariables
      >({
        mutation: gql`
          mutation DeleteSectionItem($itemId: Int!) {
            deleteSectionItem(itemId: $itemId)
          }
        `,
        variables: { itemId: id },
        refetchQueries: [this._GET_FRONTPAGE_SECTIONS_QUERY],
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteSectionItem) {
            throw "Error deleting Section Item";
          }
        }),
      );
  }

  private sanitizeFilename(filename: string): string {
    return extractFilename(filename);
  }
}
