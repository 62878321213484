export function extractFileBasename(file: string): string {
  const startInd = Math.max(0, file.lastIndexOf("/") + 1);
  const endInd = file.lastIndexOf(".");
  return startInd >= 0 && endInd > startInd
    ? file.slice(startInd, endInd)
    : file;
}

export function extractFileExtension(file: string): string {
  const startInd = file.lastIndexOf(".") + 1;
  return file.slice(startInd);
}

export function extractFilename(file: string): string {
  return `${extractFileBasename(file)}.${extractFileExtension(file)}`;
}
