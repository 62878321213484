<mat-dialog-content>
  <div class="header">
    <div class="eqi">{{ eqiText }}</div>
    <button class="close-button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="title">
    <span [ngClass]="{ pbna: sharedService.environmentIsPBNA() }">{{
      product?.name
    }}</span>
  </div>

  <div class="separator">
    <div class="line"></div>
  </div>

  <div class="content">
    @if (image) {
      <div class="img-container">
        <img [src]="image.smallThumb" />
      </div>
    }

    <div class="info">
      <div>
        <span>RENDERING</span>
        <span>{{ eqiText }} {{ product?.name }}</span>
      </div>
      <div>
        @if (sharedService.environmentIsPBNA()) {
          <button
            mat-flat-button
            class="download-btn ga-clic-download"
            [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
            [disabled]="downloading"
            (click)="downloadImage()"
          >
            Download <mat-icon iconPositionEnd>download</mat-icon>
            @if (downloading) {
              <mat-spinner [diameter]="30"></mat-spinner>
            }
          </button>
        } @else {
          <button
            mat-stroked-button
            class="download-btn ga-clic-download"
            [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
            [disabled]="downloading"
            (click)="downloadImage()"
          >
            Download <mat-icon iconPositionEnd>download</mat-icon>
            @if (downloading) {
              <mat-spinner [diameter]="30"></mat-spinner>
            }
          </button>
        }
      </div>
    </div>
  </div>

  @if (zipAvailable()) {
    @if (sharedService.environmentIsPBNA()) {
      <button
        mat-flat-button
        type="button"
        class="zip ga-clic-download"
        [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
        (click)="downloadZip()"
      >
        Download ZIP assets <mat-icon iconPositionEnd>download</mat-icon>
      </button>
    } @else {
      <button
        mat-stroked-button
        type="button"
        class="zip ga-clic-download"
        [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
        (click)="downloadZip()"
      >
        Download ZIP assets <mat-icon iconPositionEnd>download</mat-icon>
      </button>
    }
  }
</mat-dialog-content>
