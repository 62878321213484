import { Directive, inject, InjectFlags } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Directive()
export abstract class StronglyTypedMatDialog<DialogData, DialogResult> {
  protected _data: DialogData | null = inject(MAT_DIALOG_DATA, {
    optional: true,
  });
  protected _matDialogRef: MatDialogRef<
    StronglyTypedMatDialog<DialogData, DialogResult>,
    DialogResult
  > = inject(MatDialogRef);
}
