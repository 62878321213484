import { DestroyRef, inject, Injectable, Signal, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { UploadService } from "./upload.service";
import {
  AddColorInput,
  Color as ColorGQL,
  UpdateColorInput as UpdateColorInputGQL,
} from "../types/graphql/graphql.type";
import { Color } from "../types/color.type";
import { environment } from "src/environments/environment";

export type CreateColorInput = AddColorInput;
export type UpdateColorInput = UpdateColorInputGQL;

type ColorsResponse = {
  colors: ColorGQL[];
};

type CreateColorResponse = {
  createColor: ColorGQL;
};

type CreateColorVariables = {
  color: AddColorInput;
};

type UpdateColorResponse = {
  updateColor: ColorGQL;
};

type UpdateColorVariables = {
  color: UpdateColorInputGQL;
};

type DeleteColorResponse = {
  deleteColor: boolean;
};

type DeleteColorVariables = {
  colorId: number;
};

export const COLOR_FIELDS = gql`
  fragment ColorFields on Color {
    id
    name
    code
    image
  }
`;

@Injectable({
  providedIn: "root",
})
export class ColorsService {
  private _apollo = inject(Apollo);
  private _uploadService = inject(UploadService);
  private _destroyRef = inject(DestroyRef);

  readonly IMAGE_UPLOAD_FOLDER: string = "colors";

  private readonly _STATIC_FILES: string[] = [
    "beige-oyster.png",
    "black-chrome-gun-metal.png",
    "black.png",
    "blue.png",
    "brown-black-rust-safeway.png",
    "clear.png",
    "cocoa-maple-wood.png",
    "coppervein.png",
    "dark-stain.png",
    "delhaize-lozier-silver.png",
    "green.png",
    "grey.png",
    "ivory.png",
    "light-stain.png",
    "list.txt",
    "orange.png",
    "pewter.png",
    "purple.png",
    "qt-silver-tiger-drylac.png",
    "red.png",
    "silver-silverein.png",
    "white.png",
    "wood.png",
    "yellow.png",
    "zinc.png",
  ];

  private _GET_COLORS_QUERY = gql`
    query Colors {
      colors {
        ...ColorFields
      }
    }
  `;

  private _colors = signal<Color[]>([]);
  get colors(): Signal<Color[]> {
    this.fetchColors();
    return this._colors.asReadonly();
  }

  private _loaded = signal<boolean>(false);
  loaded = this._loaded.asReadonly();

  private _fetched: boolean = false;

  private fetchColors(): void {
    if (this._fetched) {
      return;
    }

    this._fetched = true;
    this._apollo
      .watchQuery<ColorsResponse>({
        query: this._GET_COLORS_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { colors } }) =>
          colors.map((color) => this.colorGQLtoColor(color)),
        takeUntilDestroyed(this._destroyRef),
        ),
      )
      .subscribe((colors) => {
        this._colors.set(colors);
        this._loaded.set(true);
      });
  }

  private colorGQLtoColor(color: ColorGQL): Color {
    return {
      id: color.id,
      name: color.name,
      image: this.parseImage(color.image),
      code: color.code,
    };
  }

  private parseImage(name: string): string | undefined {
    if (name === "") return undefined;

    if (this._STATIC_FILES.includes(name))
      return `assets/images/products/colors/${name}`;

    return `${environment.container}/assets/${this.IMAGE_UPLOAD_FOLDER}/${name}`;
  }

  uploadImage(file: File): Observable<string> {
    return this._uploadService.uploadAsset(file, this.IMAGE_UPLOAD_FOLDER)
      .upload$;
  }

  createColor(colorData: CreateColorInput): Observable<void> {
    return this._apollo
      .mutate<CreateColorResponse, CreateColorVariables>({
        mutation: gql`
          mutation CreateColor($color: AddColorInput!) {
            createColor(color: $color) {
              ...ColorFields
            }
          }
        `,
        variables: { color: colorData },
        update: (cache, { data }) => {
          const existingData = cache.readQuery<ColorsResponse>({
            query: this._GET_COLORS_QUERY,
          });
          if (existingData && data?.createColor) {
            cache.writeQuery({
              query: this._GET_COLORS_QUERY,
              data: { colors: [...existingData.colors, data?.createColor] },
            });
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateColor(colorData: UpdateColorInput): Observable<void> {
    return this._apollo
      .mutate<UpdateColorResponse, UpdateColorVariables>({
        mutation: gql`
          mutation UpdateColor($color: UpdateColorInput!) {
            updateColor(color: $color) {
              ...ColorFields
            }
          }
        `,
        variables: { color: colorData },
      })
      .pipe(map(({ data }) => {}));
  }

  deleteColor(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteColorResponse, DeleteColorVariables>({
        mutation: gql`
          mutation DeleteColor($colorId: Int!) {
            deleteColor(colorId: $colorId)
          }
        `,
        variables: { colorId: id },
        update: (cache, { data }) => {
          if (data?.deleteColor) {
            const identity = cache.identify({ id, __typename: "Color" });
            cache.evict({ id: identity, broadcast: true });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteColor) {
            throw "Error deleting Color";
          }
        }),
      );
  }
}
