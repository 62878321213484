export const HomePageBannerImageKeyNames = [
  "asset-homepage-top-left",
  "asset-homepage-button-top-left",
  "asset-homepage-top-right",
  "asset-homepage-button-top-right",
  "asset-homepage-top-right-1",
  "asset-homepage-button-top-right-1",
  "asset-homepage-top-right-2",
  "asset-homepage-button-top-right-2",
  "asset-homepage-bottom-right",
  "asset-homepage-button-bottom-right",
] as const;
export type HomePageBannerImageKey =
  (typeof HomePageBannerImageKeyNames)[number];

export type HomePageBannerImage = {
  readonly id: number;
  readonly key: HomePageBannerImageKey;
  readonly displayName: string;
  readonly image: string;
};

export const HomePageBannerLinkKeyNames = [
  "action-homepage-top-left",
  "action-homepage-top-right",
  "action-homepage-button-top-right-1",
  "action-homepage-button-top-right-2",
  "action-homepage-bottom-right",
  "action-homepage-button-bottom-right",
] as const;
export type HomePageBannerLinkKey = (typeof HomePageBannerLinkKeyNames)[number];

export type HomePageBannerLink = {
  readonly id: number;
  readonly key: HomePageBannerLinkKey;
  readonly displayName: string;
  readonly url: string;
};

export type HomePageBadge = {
  readonly id: number;
  readonly key: string;
  readonly displayName: string;
  readonly image: string;
};
