import { NgModule } from "@angular/core";
import { environment } from "src/environments/environment";

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { BrowserModule } from "@angular/platform-browser";
import { createFragmentRegistry } from "@apollo/client/cache";
import { BUG_FIELDS } from "../services/bugs.service";
import { COLOR_FIELDS } from "../services/colors.service";
import {
  CATEGORY_FIELDS,
  CATEGORY_WITH_PRODUCTS_FIELDS,
} from "../services/categories.service";
import {
  PRODUCT_EDIT_SEARCH_FIELDS,
  PRODUCT_FIELDS,
} from "../services/products.service";
import { SETTING_FIELDS } from "../services/settings.service";
import {
  FRONTPAGE_SECTION_FIELDS,
  FRONTPAGE_SECTION_ITEM_FIELDS,
} from "../services/frontpage-section.service";

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) {
    console.log(JSON.stringify(networkError));
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
  },
  query: {
    fetchPolicy: "network-only",
  },
};

export function createApollo(httpLink: HttpLink) {
  return {
    link: link.concat(httpLink.create({ uri: environment.graphqlEndPoint })),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(
        CATEGORY_FIELDS,
        CATEGORY_WITH_PRODUCTS_FIELDS,
        PRODUCT_FIELDS,
        PRODUCT_EDIT_SEARCH_FIELDS,
        BUG_FIELDS,
        COLOR_FIELDS,
        SETTING_FIELDS,
        FRONTPAGE_SECTION_FIELDS,
        FRONTPAGE_SECTION_ITEM_FIELDS,
      ),
    }),
    defaultOptions: defaultOptions,
  };
}

@NgModule({
  exports: [BrowserModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
