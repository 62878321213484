import { inject, Injectable } from "@angular/core";
import { defer, Observable, of } from "rxjs";
import { extractFilename } from "../tools/file.tools";
import { DevicesService } from "../core/services/devices.service";

@Injectable({
  providedIn: "root",
})
export class DownloadService {
  private _devicesService = inject(DevicesService);

  downloadFile(url: string, downloadName?: string): Observable<void> {
    if (this._devicesService.isMobile()) {
      window.open(url, "_blank");
      return of();
    }

    return defer(() => this.downloadFileAsync(url, downloadName));
  }

  private async downloadFileAsync(
    url: string,
    downloadName?: string,
  ): Promise<void> {
    const response = await fetch(url);

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = downloadName ?? extractFilename(url);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}
