import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  Signal,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import {
  AddCategoryInput,
  Category as CategoryGQL,
  UpdateCategoryInput as UpdateCategoryInputGQL,
} from "../types/graphql/graphql.type";
import { Category, CategoryWithProducts } from "../types/category.type";
import { productGQLtoProduct } from "../tools/cms.tools";

export type CreateCategoryInput = AddCategoryInput;
export type UpdateCategoryInput = Omit<UpdateCategoryInputGQL, "id"> & {
  id: number;
};

type CategoriesResponse = {
  categories: CategoryGQL[];
};

type CategoriesWithProductsResponse = {
  productsByCategories: CategoryGQL[];
};

type CategoriesWithProductsVariables = {
  categoryIds: number[];
};

type CategoryWithProductsResponse = {
  productsByCategory: CategoryGQL;
};

type CategoryWithProductsVariables = {
  categoryId: number;
};

type CreateCategoryResponse = {
  createCategory: CategoryGQL;
};

type CreateCategoryVariables = {
  category: AddCategoryInput;
};

type UpdateCategoryResponse = {
  updateCategory: CategoryGQL;
};

type UpdateCategoryVariables = {
  category: UpdateCategoryInputGQL;
};

type DeleteCategoryResponse = {
  deleteCategory: {
    deletedCategories: number[];
    deletedProducts: number[];
  };
};

type DeleteCategoryVariables = {
  categoryId: number;
};

export const CATEGORY_FIELDS = gql`
  fragment CategoryFields on Category {
    id
    name
    displayOrder
  }
`;

export const CATEGORY_WITH_PRODUCTS_FIELDS = gql`
  fragment CategoryWithProductsFields on Category {
    id
    name
    displayOrder
    parent {
      id
      name
      parent {
        id
        name
      }
    }
    products {
      ...ProductFields
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private _apollo = inject(Apollo);
  private _destroyRef = inject(DestroyRef);

  private _GET_CATEGORIES_QUERY = gql`
    query Categories {
      categories {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
          }
        }
      }
    }
  `;

  private _categories = signal<Category[]>([]);
  get categories(): Signal<Category[]> {
    this.fetchCategories();
    return this._categories.asReadonly();
  }

  flattenedCategoriesMap = computed(() => {
    const categories: { [id: string]: Category } = {};
    for (const l1Category of this.categories()) {
      categories[l1Category.id] = l1Category;
      for (const l2Category of l1Category.children) {
        categories[l2Category.id] = l2Category;
        for (const l3Category of l2Category.children) {
          categories[l3Category.id] = l3Category;
        }
      }
    }
    return categories;
  });

  flattenedCategories = computed(() =>
    Object.values(this.flattenedCategoriesMap()),
  );

  private _loaded = signal<boolean>(false);
  loaded = this._loaded.asReadonly();

  private _fetched: boolean = false;

  private fetchCategories(): void {
    if (this._fetched) {
      return;
    }

    this._fetched = true;
    this._apollo
      .watchQuery<CategoriesResponse>({
        query: this._GET_CATEGORIES_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { categories } }) =>
          categories.map((category) => this.parseCategoryGQL(category)),
        ),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((categories) => {
        this._categories.set(categories);
        this._loaded.set(true);
      });
  }

  private parseCategoryGQL(category: CategoryGQL, parentId?: number): Category {
    return {
      id: category.id,
      name: category.name,
      displayOrder: category.displayOrder,
      parentId,
      children:
        category.children && category.children?.length > 0
          ? category.children.map((c) => this.parseCategoryGQL(c, category.id))
          : [],
    };
  }

  getCategoriesWithProducts(
    ids?: number[],
  ): Observable<CategoryWithProducts[]> {
    if (!ids) {
      return this._apollo
        .watchQuery<CategoriesResponse>({
          query: gql`
            query AllCategoriesWithProducts {
              categories {
                ...CategoryWithProductsFields
                children {
                  ...CategoryWithProductsFields
                  children {
                    ...CategoryWithProductsFields
                  }
                }
              }
            }
          `,
        })
        .valueChanges.pipe(
          map(({ data: { categories } }) =>
            categories.map((category) =>
              this.parseCategoryWithProductsGQL(category),
            ),
          ),
        );
    }

    return this._apollo
      .watchQuery<
        CategoriesWithProductsResponse,
        CategoriesWithProductsVariables
      >({
        query: gql`
          query CategoriesWithProducts($categoryIds: [Int!]!) {
            productsByCategories(categoryIds: $categoryIds) {
              ...CategoryWithProductsFields
              children {
                ...CategoryWithProductsFields
                children {
                  ...CategoryWithProductsFields
                }
              }
            }
          }
        `,
        variables: { categoryIds: ids },
      })
      .valueChanges.pipe(
        map(({ data: { productsByCategories } }) =>
          productsByCategories.map((category) =>
            this.parseCategoryWithProductsGQL(category),
          ),
        ),
      );
  }

  getCategoryWithProducts(id: number): Observable<CategoryWithProducts> {
    return this._apollo
      .watchQuery<CategoryWithProductsResponse, CategoryWithProductsVariables>({
        query: gql`
          query CategoryWithProducts($categoryId: Int!) {
            productsByCategory(categoryId: $categoryId) {
              ...CategoryWithProductsFields
              children {
                ...CategoryWithProductsFields
                children {
                  ...CategoryWithProductsFields
                }
              }
            }
          }
        `,
        variables: { categoryId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByCategory } }) =>
          this.parseCategoryWithProductsGQL(productsByCategory),
        ),
      );
  }

  private parseCategoryWithProductsGQL(
    category: CategoryGQL,
  ): CategoryWithProducts {
    return {
      id: category.id,
      name: category.name,
      displayOrder: category.displayOrder,
      products:
        category.products && category.products.length > 0
          ? category.products
              .map((p) => productGQLtoProduct(p))
              .sort((a, b) => a.sortId - b.sortId)
          : [],
      children:
        category.children && category.children.length > 0
          ? category.children.map((c) => this.parseCategoryWithProductsGQL(c))
          : [],
      parent: category.parent
        ? {
            id: category.parent.id,
            name: category.parent.name,
            parent: category.parent.parent
              ? {
                  id: category.parent.parent.id,
                  name: category.parent.parent.name,
                }
              : undefined,
          }
        : undefined,
    };
  }

  createCategory(categoryData: CreateCategoryInput): Observable<void> {
    return this._apollo
      .mutate<CreateCategoryResponse, CreateCategoryVariables>({
        mutation: gql`
          mutation CreateCategory($category: AddCategoryInput!) {
            createCategory(category: $category) {
              ...CategoryFields
              parent {
                id
              }
              children {
                ...CategoryFields
                children {
                  ...CategoryFields
                }
              }
            }
          }
        `,
        variables: { category: categoryData },
        update: (cache, { data }) => {
          if (data) {
            const existingData = cache.readQuery<CategoriesResponse>({
              query: this._GET_CATEGORIES_QUERY,
            });
            if (existingData) {
              const newCategory = data.createCategory;
              const newCategoryParentId = newCategory.parent?.id;
              if (newCategoryParentId) {
                cache.writeQuery({
                  query: this._GET_CATEGORIES_QUERY,
                  data: {
                    categories: existingData.categories.map((l1Category) => {
                      if (l1Category.id === newCategoryParentId) {
                        return {
                          ...l1Category,
                          children: [...l1Category.children, newCategory],
                        };
                      }

                      return {
                        ...l1Category,
                        children: l1Category.children.map((l2Category) => {
                          if (l2Category.id === newCategoryParentId) {
                            return {
                              ...l2Category,
                              children: [...l2Category.children, newCategory],
                            };
                          }
                          return l2Category;
                        }),
                      };
                    }),
                  },
                });
              } else {
                cache.writeQuery({
                  query: this._GET_CATEGORIES_QUERY,
                  data: {
                    categories: [...existingData.categories, newCategory],
                  },
                });
              }
            }
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateCategory(categoryData: UpdateCategoryInput): Observable<void> {
    return this._apollo
      .mutate<UpdateCategoryResponse, UpdateCategoryVariables>({
        mutation: gql`
          mutation UpdateCategory($category: UpdateCategoryInput!) {
            updateCategory(category: $category) {
              ...CategoryFields
            }
          }
        `,
        variables: {
          category: categoryData,
        },
      })
      .pipe(map(({ data }) => {}));
  }

  deleteCategory(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteCategoryResponse, DeleteCategoryVariables>({
        mutation: gql`
          mutation DeleteCategory($categoryId: Int!) {
            deleteCategory(categoryId: $categoryId) {
              deletedCategories
              deletedProducts
            }
          }
        `,
        variables: { categoryId: id },
        update: (cache, { data }) => {
          if (data) {
            data.deleteCategory.deletedCategories.map((c) => {
              const identity = cache.identify({ id, __typename: "Category" });
              cache.evict({ id: identity, broadcast: true });
            });
            data.deleteCategory.deletedProducts.map((c) => {
              const identity = cache.identify({ id, __typename: "Product" });
              cache.evict({ id: identity, broadcast: true });
            });
            cache.gc();
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }
}
