<div *ngFor="let page of pageItems"
     class="container landscape">
  <div class="header">
    <div class="line line-1"></div>
    <div class="line line-2"></div>
    <div class="name">
      Table of Content
    </div>
    <!-- <img class="logo"
         src="assets/images/sellsheet/Fit_To_Win.png"> -->
  </div>
  <div class="content">
    <a *ngFor="let item of page.items"
       class="item"
       [href]="'#' + item.fragment"
       [style.gridRowStart]="item.row"
       [style.gridColumnStart]="item.col">
      <div class="index">{{item.index}}</div>
      <div class="separator"></div>
      <div class="category">{{item.name}}</div>
    </a>
  </div>
</div>