import { NgClass } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  inject,
  DestroyRef,
  computed,
  input,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { SharedService } from "src/app/shared/services/shared.service";
import { ColorsService } from "src/app/services/colors.service";
import { BugsService } from "src/app/services/bugs.service";
import { MatDialogService } from "src/app/services/mat-dialog.service";
import {
  DisplayStyle,
  ISiteSettings,
} from "src/app/store/models/ISiteSettings";
import { AppState } from "src/app/store/state/app.state";
import { Product } from "src/app/types/product.type";
import { Bug } from "src/app/types/bug.type";
import { ProductViewPopupComponent } from "../product-view-popup/product-view-popup.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

declare var $: any;

@Component({
  selector: "fc-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  standalone: true,
  imports: [NgClass, MatButtonModule, MatIconModule],
})
export class CardComponent implements OnInit, AfterViewInit {
  product = input.required<Product>();
  @Input() isPrint?: boolean;

  private _destroyRef = inject(DestroyRef);
  private _colorsService = inject(ColorsService);
  private _bugsService = inject(BugsService);
  private _matDialogService = inject(MatDialogService);

  isCardLayout = true;

  colors = this._colorsService.colors;
  bugs = this._bugsService.bugs;

  productColors = computed(() => {
    return (
      this.product().colors?.map((pc) =>
        this.colors().find((c) => c.id === pc.id),
      ) ?? []
    );
  });

  isProductColorAvailable = computed(() => {
    const productColors = this.productColors();
    if (productColors.length > 0) {
      return !!productColors[0] && productColors[0].image;
    }
    return false;
  });

  productImage = computed(() => {
    const images = this.product().images;
    return images.length > 0
      ? images[0].smallThumb
      : "assets/images/common/no_image.svg";
  });

  productTitleGA = computed(() => {
    const product = this.product();
    if (this.sharedService.environmentIsPBNA()) {
      return `${product.eqi}-${product.name}`;
    }

    const productColor =
      product && product.colors.length > 0 ? product.colors[0] : undefined;
    return `${productColor?.fullEqi ?? product?.eqi}-${product?.name}`;
  });

  eqiText = computed(() => {
    const product = this.product();
    if (!this.sharedService.environmentIsPBNA()) {
      let eqi =
        product.colors.length > 0 && product.colors[0].fullEqi !== undefined
          ? product?.eqi
          : undefined;

      if (this.sharedService.environmentIsMCOE() && eqi?.startsWith("900")) {
        eqi = undefined;
      }
      return eqi;
    } else {
      return product?.eqi !== undefined
        ? `SAP / POLR #: ${product.eqi}`
        : undefined;
    }
  });

  dimensions = computed(() => {
    if (this.sharedService.environmentIsPBNA())
      return this.product().properties.dimensions;

    const w = this.product().width;
    const h = this.product().height;
    const d = this.product().depth;
    return w !== undefined || h !== undefined || d
      ? `${w} x ${d} x ${h}`
      : undefined;
  });

  productBugs = computed(() => {
    return this.product()
      .bugs.map((pb) => this.bugs().find((c) => c.id === pb))
      .filter((pb): pb is Bug => !!pb);
  });

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.store
      .select((state) => state.siteSettings)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
      });
  }

  ngAfterViewInit(): void {
    $('[data-toggle="tooltip"]').tooltip();
  }

  navigateToBugId(bugId: number) {
    $(".badge").tooltip("hide");

    this.sharedService.navigateToBugId(bugId);
  }

  download() {
    this._matDialogService.createProductDownloadPopup(this.product());
  }

  notes = computed(() => {
    const product = this.product();
    if (product.bugs.find((b) => b === 4)) {
      if (product.notes) {
        const str1 = product.notes.replace(
          "<hi>",
          '<div class="badge badge-pill badge-warning">',
        );
        const str2 = str1.replace("</hi>", "</div><div>");
        return `${str2}</div>`;
      }
    }
    return product.notes;
  });

  showThumbnailModal() {
    this._matDialogService.open(ProductViewPopupComponent, {
      data: { product: this.product() },
      width: "calc(100vw - 30px)",
      maxWidth: "1410px",
      height: "calc(100vh - 30px)",
      maxHeight: "calc(100vh - 30px)",
      ariaLabel: this.product().name,
    });
  }
}
