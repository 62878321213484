import { HttpClient, HttpEventType } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { environment } from "src/environments/environment";
import { UploadRef } from "../types/upload";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  readonly UPLOAD_IMAGE_PRODUCT_PATH: string = "upload/image/product";
  readonly UPLOAD_IMAGE_PRODUCT_BATCH_PATH: string =
    "upload/image/product?type=batch";
  readonly UPLOAD_PRODUCT_360_PATH: string = "upload/product/360";
  readonly UPLOAD_PRODUCT_VIDEO_PATH: string = "upload/product/video";
  readonly UPLOAD_EXCEL_PATH: string = "upload/excel";
  readonly UPLOAD_ASSET_BASE_PATH: string = "upload/asset";

  private _httpClient = inject(HttpClient);

  uploadProductImage(file: File, useOriginalName: boolean = false): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_IMAGE_PRODUCT_PATH}`,
      useOriginalName,
    );
  }

  uploadProductsImages(
    file: File,
    useOriginalName: boolean = false,
  ): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_IMAGE_PRODUCT_BATCH_PATH}`,
      useOriginalName,
    );
  }

  uploadProduct360Images(
    file: File,
    useOriginalName: boolean = false,
  ): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_PRODUCT_360_PATH}`,
      useOriginalName,
    );
  }

  uploadProductVideo(
    file: File,
    useOriginalName: boolean = false,
  ): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_PRODUCT_VIDEO_PATH}`,
      useOriginalName,
    );
  }

  uploadProductsExcel(file: File, useOriginalName: boolean = false): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_EXCEL_PATH}`,
      useOriginalName,
    );
  }

  uploadAsset(
    file: File,
    folder: string,
    useOriginalName: boolean = false,
  ): UploadRef {
    return this.upload(
      file,
      `${environment.endPoint}/${this.UPLOAD_ASSET_BASE_PATH}?folder=${folder}`,
      useOriginalName,
    );
  }

  private updateFilename(file: File): File {
    const ext = file.name.split(".").pop();
    const filename = `${uuidv4()}.${ext}`;
    return new File([file], filename, { type: file.type });
  }

  private upload(
    file: File,
    path: string,
    useOriginalName: boolean,
  ): UploadRef {
    const fileToUpload = useOriginalName ? file : this.updateFilename(file);
    const formData = new FormData();
    formData.append("file", fileToUpload);

    const progress$ = new BehaviorSubject<number>(0);

    const upload$ = this._httpClient
      .post(path, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((events) => {
          if (events.type === HttpEventType.UploadProgress) {
            progress$.next(
              events.total && events.total > 0
                ? events.loaded / events.total
                : 0,
            );
          }

          if (events.type === HttpEventType.Response) {
            if (events.status !== 200) {
              throw `Error while uploading file - status: ${events.status} - msg: ${events.body}`;
            }

            progress$.next(1);

            return true;
          }

          return false;
        }),
        filter((v) => v),
        map(() => fileToUpload.name),
      );
    return { upload$, progress$ };
  }
}
