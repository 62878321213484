<nav
  class="navbar navbar-expand-lg navbar-light navbar-custom"
  [ngClass]="{
    fsv: sharedService.environmentIsFSV(),
    pbna: sharedService.environmentIsPBNA(),
  }"
>
  <div class="container">
    <div class="row w-100">
      @if (!showMobileSearchBar) {
        <div
          class="col-6 col-lg-2 logo"
          [ngClass]="{ 'col-6 col-lg-2': !isMobile, 'col-6': isMobile }"
        >
          <a
            class="navbar-brand"
            (click)="goToHome()"
            [ngClass]="{
              auth: !isFsvSecureAccess,
              fsv: sharedService.environmentIsFSV(),
            }"
          >
            @if (sharedService.environmentIsMCOE()) {
              <div [ngClass]="{ mobile: isMobile === true }">
                <img src="assets/images/navbar/group-173@3x.png" />
                <span [ngClass]="{ mobile: isMobile === true }"
                  >Merchandising Center of Excellence</span
                >
              </div>
            }

            @if (sharedService.environmentIsPBNA()) {
              <div class="pbna">
                <img
                  src="assets/images/navbar/Logo_Over_Black.png"
                  class="pbna"
                />
              </div>
            }

            @if (sharedService.environmentIsFSV()) {
              <div class="fsv">
                <img
                  src="assets/images/navbar/pepsico-logo@3x.png"
                  class="fsv"
                />
              </div>
            }
          </a>
        </div>
      }

      @if (!isMobile && isFsvSecureAccess) {
        <div class="col-6">
          <fc-search></fc-search>
        </div>
        <div
          class="col-4 right-side"
          [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
        >
          @if (
            sharedService.environmentIsMCOE() ||
            sharedService.environmentIsPBNA()
          ) {
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 3px;
                flex: 1;
              "
            >
              <a
                class="btn-4"
                style="cursor: pointer"
                (click)="switchDisplayStyle()"
              >
                @if (isCardLayout) {
                  <img
                    [src]="
                      sharedService.environmentIsPBNA()
                        ? 'assets/images/products/CardViewOnPBNA.png'
                        : 'assets/images/products/CardViewOn.png'
                    "
                    class="ga-clic-homepage"
                    data-titlega="Header - Display style - List view"
                    style="height: 45px"
                  />
                } @else {
                  <img
                    [src]="
                      sharedService.environmentIsPBNA()
                        ? 'assets/images/products/ListViewOnPBNA.png'
                        : 'assets/images/products/ListViewOn.png'
                    "
                    class="ga-clic-homepage"
                    data-titlega="Header - Display style - Card view"
                    style="height: 45px"
                  />
                }
              </a>
            </div>
          }

          @if (sharedService.environmentIsMCOE()) {
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].faqClicked()
                    "
                    data-titlega="Header - FAQ"
                    >FAQ</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].contactClicked()
                    "
                    data-titlega="Header - Contact"
                    >Contact</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].displayHelpModalClicked()
                    "
                    data-titlega="Header - Help"
                  >
                    Help
                  </a>
                </li>
              </ul>
              <div id="buttons-container-temp">
                <button
                  class="btn btn-2 my-2 my-sm-0 ga-clic-homepage button-nav-temp-1"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].inventoryClicked()
                  "
                  data-titlega="Header - Inventory"
                >
                  Sell Sheets
                </button>
                <button
                  class="btn btn-1 my-2 my-sm-0 ga-clic-homepage button-nav-temp-2"
                  data-toggle="modal"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].displayPrintModalClicked()
                  "
                  data-titlega="Header - Print PDF"
                >
                  Download & Print PDF
                </button>
              </div>
            </div>
          }

          @if (sharedService.environmentIsPBNA()) {
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              <ul class="navbar-nav pbna">
                <li class="nav-item pbna">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].faqClicked()
                    "
                    data-titlega="Header - FAQ"
                    >How to Order</a
                  >
                </li>
                <li class="nav-item pbna">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].contactClicked()
                    "
                    data-titlega="Header - Contact"
                    >Contact</a
                  >
                </li>
                <li class="nav-item pbna">
                  <a
                    class="nav-link ga-clic-homepage"
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].displayHelpModalClicked()
                    "
                    data-titlega="Header - Help"
                  >
                    Help
                  </a>
                </li>
              </ul>
              <div id="buttons-container-temp">
                <button
                  class="btn btn-2 my-2 my-sm-0 ga-clic-inventory button-nav-temp-1 pbna"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].inventoryClicked()
                  "
                  data-titlega="Header - Inventory"
                >
                  Sell Sheets
                </button>
                <button
                  class="btn btn-1 my-2 my-sm-0 ga-clic-homepage button-nav-temp-2"
                  data-toggle="modal"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].displayPrintModalClicked()
                  "
                  data-titlega="Header - Print PDF"
                >
                  Download &<br />
                  Print PDF
                </button>
              </div>
            </div>
          }

          @if (sharedService.environmentIsFSV()) {
            <ul class="navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link ga-clic-homepage"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].faqClicked('faq')
                  "
                  data-titlega="Header - FAQ"
                  >FAQ</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link ga-clic-homepage"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].contactClicked()
                  "
                  data-titlega="Header - Contact"
                  >Contact</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link ga-clic-homepage"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].displayHelpModalClicked()
                  "
                  data-titlega="Header - Help"
                >
                  Help
                </a>
              </li>
            </ul>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
              "
            >
              <button
                class="btn btn-2 my-2 my-sm-0 mr-3 ga-clic-homepage"
                [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
                data-toggle="modal"
                (click)="
                  this.clientFunctions[
                    sharedService.getEnvironmentId()
                  ].inventoryClicked()
                "
                data-titlega="Header - Inventory"
                style="line-height: unset; width: 8.5rem"
              >
                Reports, Forms<br />& Sell Sheets
              </button>
              <button
                class="btn btn-1 my-2 my-sm-0 ga-clic-homepage"
                [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
                data-toggle="modal"
                (click)="
                  this.clientFunctions[
                    sharedService.getEnvironmentId()
                  ].displayPrintModalClicked()
                "
                data-titlega="Header - Print PDF"
              >
                Download & Print PDF
              </button>
            </div>
          }
        </div>
      }

      @if (isMobile && isFsvSecureAccess) {
        <div
          [ngClass]="{
            'col-6 p-0': !showMobileSearchBar,
            'col-10': showMobileSearchBar,
          }"
        >
          @if (!showMobileSearchBar) {
            <div class="right-side" [ngClass]="{ 'is-mobile': isMobile }">
              <button
                class="navbar-toggler menu-toggle"
                type="button"
                [ngClass]="{ 'is-mobile': isMobile }"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="mobile-actions">
                <button
                  class="btn ga-clic-homepage"
                  data-toggle="modal"
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].displayPrintModalClicked()
                  "
                  data-titlega="Header - Print PDF"
                >
                  <i
                    class="material-icons ga-clic-homepage"
                    data-titlega="Header - Print PDF"
                    >print</i
                  >
                </button>
              </div>
              <div class="mobile-actions">
                <a (click)="toggleShowMobileSearchBar()">
                  <i class="material-icons">search</i>
                </a>
              </div>
            </div>
          } @else {
            <div class="w-100 h-100">
              <fc-search></fc-search>
            </div>
          }
        </div>
      }

      @if (isMobile && showMobileSearchBar) {
        <div class="col-2">
          <div class="cancel">
            <button class="btn" (click)="cancelSearchClicked()">Cancel</button>
          </div>
        </div>
      }
    </div>
  </div>
</nav>

<!-- Modal -->
@if (isFsvSecureAccess) {
  <div
    class="modal fade"
    id="print-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="printModal"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="printModal">Print</h5>
          <div class="print-spinner spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="modal-header">
          <p class="description mb-0">
            A PDF of your selected catalog sections will automatically be
            generated and downloaded by pressing the "Print" button. This may
            take 1 - 2 minutes depending on the amount of items selected. If you
            are using a pop-up blocker, please disable it and try to print
            again.
          </p>
        </div>
        <div class="modal-body">
          <div class="row">
            @if (sharedService.environmentIsMCOE()) {
              <div class="col-6">
                <div class="form-check py-1 custom-control custom-checkbox">
                  <input
                    class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                    type="checkbox"
                    value=""
                    id="special-offers"
                    (click)="toggleCheckbox('special-offers')"
                    data-titlega="Special Offers"
                  />
                  <label
                    class="form-check-label custom-control-label"
                    for="special-offers"
                  >
                    Special Offers
                  </label>
                </div>
              </div>
            }

            <div class="col-6">
              <div class="form-check py-1 custom-control custom-checkbox">
                <input
                  class="form-check-input custom-control-input print-checkbox special-categories ga-clic-print-checkbox"
                  type="checkbox"
                  value=""
                  id="full-catalog"
                  (click)="toggleCheckbox('full-catalog')"
                  data-titlega="Full Catalog"
                />
                <label
                  class="form-check-label custom-control-label"
                  for="full-catalog"
                >
                  Full Catalog
                </label>
              </div>
            </div>

            @if (
              sharedService.environmentIsMCOE() ||
              sharedService.environmentIsPBNA()
            ) {
              <div class="row">
                @for (l1Category of l1Categories(); track l1Category.id) {
                  <div class="col-6">
                    <div class="form-check py-1 custom-control custom-checkbox">
                      <input
                        class="form-check-input custom-control-input print-checkbox l1-categories ga-clic-print-checkbox"
                        type="checkbox"
                        value=""
                        [id]="l1Category.id"
                        (click)="toggleCheckbox(l1Category.id)"
                        [attr.data-titlega]="l1Category.name"
                      />
                      <label
                        class="form-check-label custom-control-label"
                        [for]="l1Category.id"
                      >
                        {{ l1Category.name }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            }

            @if (sharedService.environmentIsFSV()) {
              <div class="col-6"></div>
              <div class="col-6">
                <div class="form-check py-1 custom-control custom-checkbox">
                  <input
                    class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                    type="checkbox"
                    value=""
                    id="header-cards"
                    (click)="toggleCheckbox('header-cards')"
                    data-titlega="Header Cards"
                  />
                  <label
                    class="form-check-label custom-control-label"
                    for="header-cards"
                  >
                    Header Cards
                  </label>
                </div>
              </div>
              <div class="col-6"></div>
              <div class="col-6">
                <div class="form-check py-1 custom-control custom-checkbox">
                  <input
                    class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                    type="checkbox"
                    value=""
                    id="accessories"
                    (click)="toggleCheckbox('accessories')"
                    data-titlega="Accessories"
                  />
                  <label
                    class="form-check-label custom-control-label"
                    for="accessories"
                  >
                    Accessories
                  </label>
                </div>
              </div>
            }
          </div>

          @for (selectData of allSelectsData; track $index) {
            <div class="row">
              <div class="col-5">
                <div class="form-check py-1 custom-control custom-checkbox">
                  <input
                    class="form-check-input custom-control-input print-checkbox l1-categories ga-clic-print-checkbox"
                    type="checkbox"
                    value=""
                    [id]="selectData.id"
                    (click)="toggleCheckbox(selectData.id)"
                    [attr.data-titlega]="selectData.name"
                  />
                  <label
                    class="form-check-label custom-control-label"
                    [for]="selectData.id"
                  >
                    {{ selectData.name }}
                  </label>
                </div>
              </div>
              <div class="col-7">
                <div class="py-1">
                  <ng-select
                    [id]="'select-' + selectData.id"
                    [loading]="selectData.isLoading"
                    dropdownPosition="top"
                    (open)="onSelectOpen(selectData)"
                    [items]="selectData.selectData"
                    bindLabel="name"
                    bindValue="id"
                    groupBy="level"
                    [multiple]="true"
                    [hideSelected]="false"
                    [closeOnSelect]="false"
                    [selectableGroup]="true"
                    [(ngModel)]="selectData.selectedData"
                    [placeholder]="selectData.placeholder"
                    [searchable]="false"
                    [selectableGroupAsModel]="true"
                    [disabled]="selectData.isDisable"
                    [groupValue]="groupValueFn"
                  >
                    <ng-template
                      ng-multi-label-tmp
                      let-items="items"
                      let-clear="clear"
                    >
                      <div class="ng-value">
                        @if (items.length === 1) {
                          <span class="ng-value-label"
                            >1 category selected</span
                          >
                        }

                        @if (items.length > 1) {
                          <span class="ng-value-label"
                            >{{ items.length }} categories selected</span
                          >
                        }
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          }
        </div>

        <div class="modal-footer">
          <button
            type="button"
            id="print-cancel"
            class="btn"
            (click)="cancelExportToPDF()"
          >
            Cancel
          </button>
          <button
            type="button"
            id="print-print"
            class="btn btn-1 ga-clic-p-print"
            (click)="exportToPDF()"
            data-titlega="Print PDF"
          >
            Print PDF
          </button>
        </div>
      </div>
    </div>
  </div>
}

@if (isFsvSecureAccess) {
  <div
    class="modal fade"
    id="help-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="helpModal"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="helpModal">Help</h5>
        </div>
        <div class="modal-header">
          @if (sharedService.environmentIsMCOE()) {
            <p class="description mb-0">
              Please send any comments or questions relating to MCOEcatalog.com
              to the following SPA Mailbox:
              <a href="mailto:FLNAMCOECatalog@pepsico.com"
                >FLNAMCOECatalog&#64;pepsico.com</a
              >. <br /><br />
              *Please note that the standard Region approved process must be
              used to place all display orders.
            </p>
          }

          @if (sharedService.environmentIsFSV()) {
            <p class="description mb-0">
              Please send any comments or questions relating to fsvcatalog.com
              to the following SPA Mailbox:
              <a href="mailto:PFSCatalog@pepsico.com"
                >PFSCatalog&#64;pepsico.com</a
              >
              <br /><br />
              *Please note that the standard Region approved process must be
              used to place all display orders.
            </p>
          }

          @if (sharedService.environmentIsPBNA()) {
            <div class="description mb-0" style="text-align: start">
              <p>
                Please send any comments or questions relating to
                PBNAPermMerch.com to the following contacts:
              </p>
              <ul>
                <li>
                  Debra Moreira,
                  <a href="mailto:debra.moreira@pepsico.com"
                    >debra.moreira&#64;pepsico.com</a
                  >
                  <ul>
                    <li>LARGE FORMAT / MASS MERCH</li>
                  </ul>
                </li>

                <li>
                  Ashton Braen,
                  <a href="mailto:ashton.braen@pepsico.com"
                    >ashton.braen&#64;pepsico.com</a
                  >
                  <ul>
                    <li>ENERGY / GAT / PROTEIN / SBUX</li>
                    <li>LARGE FORMAT / MASS MERCH / DOLLAR</li>
                  </ul>
                </li>

                <li>
                  Carina Martusevici,
                  <a href="mailto:carina.martusevici@pepsico.com"
                    >carina.martusevici&#64;pepsico.com</a
                  >
                  <ul>
                    <li>FLEX / CSDS / TEA / HBU</li>
                    <li>SMALL FORMAT / FOOD SERVICE</li>
                  </ul>
                </li>

                <li>
                  Erin Henebry,
                  <a href="mailto:erin.henebry@pepsico.com"
                    >erin.henebry&#64;pepsico.com</a
                  >
                  <ul>
                    <li>Water / VAP</li>
                    <li>DRUG</li>
                    <li>Epop</li>
                  </ul>
                </li>

                <li>
                  Roshmi Bhattacharjee,
                  <a href="mailto:roshmi.bhattacharjee@pepsico.com"
                    >roshmi.bhattacharjee&#64;pepsico.com</a
                  >
                  <ul>
                    <li>
                      Forecast Planning / SAP / Reimbursements / PO Gathering
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          }
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="help-close"
            class="btn btn-1"
            (click)="closeHelpModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
}
