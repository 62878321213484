<div class="landscape">
  <div class="content">
    <div class="row top">
      <div class="box image">
        <div class="product">
          <img [src]="image()" />
        </div>
        <div class="art">
          <img src="assets/images/sellsheet/Sell_Sheet_Art-01.png" />
        </div>
        <div class="logo">
          <img
            src="assets/images/sellsheet/flcl_3clr_nom_pos_08_transparent.png"
          />
        </div>
      </div>
      <div class="box presentation">
        <div class="name-content">
          <div class="name">
            {{ product().name }}
          </div>
        </div>
        <div class="eqi-content2"></div>
        <div class="eqi-content"></div>

        @if (eqiText()) {
          <div class="eqi">EQI {{ eqiText() }}</div>
        } @else {
          <div class="eqi">&nbsp;</div>
        }
      </div>

      @if (product().sellsheet) {
        <div class="box economics">
          <div class="subbox top">
            <div class="title">Economics</div>
            <div class="economic">
              <div class="left text">SDV Holding Power</div>
              <div class="right text border-red">
                <div class="arrow-right"></div>
                {{ product().sellsheet!.economics.SDVHoldingPower }}
              </div>
            </div>
            <div class="economic">
              <div class="left text">Retail Sales/Turn</div>
              <div class="right text border-orange">
                <div class="arrow-right"></div>
                {{ product().sellsheet!.economics.retailSales }}
              </div>
            </div>
            <div class="economic">
              <div class="left text">Annual Turns</div>
              <div class="right text border-yellow">
                <div class="arrow-right"></div>
                {{ product().sellsheet!.economics.annualTurns }}
              </div>
            </div>
            <div class="economic">
              <div class="left text">Annual Retail Sales</div>
              <div class="right text border-blue">
                <div class="arrow-right"></div>
                {{ product().sellsheet!.economics.annualRetailSales }}
              </div>
            </div>
            <div class="economic">
              <div class="left text">Annual Retail Profit</div>
              <div class="right text border-blue2">
                <div class="arrow-right"></div>
                {{ product().sellsheet!.economics.annualRetailProfit }}
              </div>
            </div>
          </div>
          <div class="subbox bottom">
            <div class="texts-content">
              <div class="text-content title">
                <div class="text">Holding Power:</div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower1 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower2 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower3 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower4 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower5 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower6 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower7 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower8 }}
                </div>
              </div>
              <div class="text-content">
                <div class="text">
                  {{ product().sellsheet!.economics.holdingPower9 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
    <div class="row bottom">
      <div class="box display-specifications">
        <div class="title">Display Specifications</div>
        <div class="texts">
          <div class="texts-content">
            <div class="text label">Dimensions:</div>
            <div class="text">
              @if (dimensions()) {
                <span>{{ dimensions() }}</span>
              } @else {
                <span>&nbsp;</span>
              }
            </div>
          </div>
          <div class="texts-content">
            <div class="text label">Unit of Measure:</div>
            <div class="text">
              {{ product().uom }}
            </div>
          </div>
          <div class="texts-content">
            <div class="text label">
              Est. Cost (Not Including Freight & Tax):
            </div>
            @if (product().sellsheet) {
              <div class="text">
                {{ product().sellsheet!.displaySpec.estCost }}
              </div>
            }
          </div>
          <div class="texts-content">
            <div class="text label">Est. Freight & Tax:</div>
            @if (product().sellsheet) {
              <div class="text">
                {{ product().sellsheet!.displaySpec.estFreight }}
              </div>
            }
          </div>
        </div>
      </div>
      <div class="box notes">
        <div class="header">
          <div class="title">Notes</div>
          @if (productColors().length > 0 && productColors()[0].image) {
            <div class="colors title">
              Colors:
              @for (productColor of productColors(); track productColor.id) {
                <div class="color ml-2">
                  <img [src]="productColor.image" />
                </div>
              }
            </div>
          } @else {
            <div class="colors title">Colors: NA</div>
          }
        </div>
        <div class="notes text" [innerHTML]="product().notes"></div>
        <div class="badges">
          @for (productBug of productBugs(); track $index) {
            <div class="badge">
              <img [src]="productBug.image" />
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="mentions">Internal Spec Sheet - Property of PepsiCo</div>
</div>
