import { computed, inject, Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import { UploadService } from "./upload.service";
import {
  HomePageBadge,
  HomePageBannerImage,
  HomePageBannerImageKey,
  HomePageBannerImageKeyNames,
  HomePageBannerLink,
  HomePageBannerLinkKey,
  HomePageBannerLinkKeyNames,
} from "../types/home-page.type";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Setting } from "../types/setting.type";
import { extractFilename } from "../tools/file.tools";
import { UploadRef } from "../types/upload";
@Injectable({
  providedIn: "root",
})
export class HomePageService {
  private _settingsService = inject(SettingsService);
  private _uploadService = inject(UploadService);

  readonly BANNER_IMAGE_FOLDER: string = "homepage";
  readonly BADGE_IMAGE_FOLDER: string = "homepageBadges";
  readonly BADGE_KEY_PREFIX: string = "badge-homepage";

  bannerImages = computed(() => {
    const settingsMap = this._settingsService.settingsKeyMap();
    const bannerImages: {
      [key in HomePageBannerImageKey]: HomePageBannerImage | undefined;
    } = {
      "asset-homepage-top-left": undefined,
      "asset-homepage-button-top-left": undefined,
      "asset-homepage-top-right": undefined,
      "asset-homepage-button-top-right": undefined,
      "asset-homepage-top-right-1": undefined,
      "asset-homepage-button-top-right-1": undefined,
      "asset-homepage-top-right-2": undefined,
      "asset-homepage-button-top-right-2": undefined,
      "asset-homepage-bottom-right": undefined,
      "asset-homepage-button-bottom-right": undefined,
    };

    for (const name of HomePageBannerImageKeyNames) {
      const s = settingsMap[name];
      if (s) {
        bannerImages[name] = this.settingToHomePageBannerImage(s);
      }
    }

    return bannerImages;
  });

  private settingToHomePageBannerImage(setting: Setting): HomePageBannerImage {
    return {
      id: setting.id,
      key: setting.key as HomePageBannerImageKey,
      displayName: setting.displayName,
      image: `${environment.container}/assets/${this.BANNER_IMAGE_FOLDER}/${setting.value}`,
    };
  }

  uploadBannerImage(file: File): UploadRef {
    return this._uploadService.uploadAsset(file, this.BANNER_IMAGE_FOLDER);
  }

  updateBannerImage(settingId: number, image: string): Observable<void> {
    return this._settingsService.updateSetting({
      id: settingId,
      value: extractFilename(image),
    });
  }

  bannerLinks = computed(() => {
    const settingsMap = this._settingsService.settingsKeyMap();
    const bannerLinks: {
      [key in HomePageBannerLinkKey]: HomePageBannerLink | undefined;
    } = {
      "action-homepage-top-left": undefined,
      "action-homepage-top-right": undefined,
      "action-homepage-button-top-right-1": undefined,
      "action-homepage-button-top-right-2": undefined,
      "action-homepage-bottom-right": undefined,
      "action-homepage-button-bottom-right": undefined,
    };

    for (const name of HomePageBannerLinkKeyNames) {
      const s = settingsMap[name];
      if (s) {
        bannerLinks[name] = this.settingToHomePageBannerLink(s);
      }
    }

    return bannerLinks;
  });

  private settingToHomePageBannerLink(setting: Setting): HomePageBannerLink {
    return {
      id: setting.id,
      key: setting.key as HomePageBannerLinkKey,
      displayName: setting.displayName,
      url: setting.value,
    };
  }

  updateBannerLink(settingId: number, url: string): Observable<void> {
    return this._settingsService.updateSetting({
      id: settingId,
      value: url,
    });
  }

  badges = computed(() =>
    this._settingsService
      .settings()
      .filter((s) => s.key.startsWith(this.BADGE_KEY_PREFIX))
      .map<HomePageBadge>((s) => this.settingToHomePageBadge(s)),
  );

  private settingToHomePageBadge(setting: Setting): HomePageBadge {
    return {
      id: setting.id,
      key: setting.key,
      displayName: setting.displayName,
      image: `${environment.container}/assets/${this.BADGE_IMAGE_FOLDER}/${setting.value}`,
    };
  }

  badgesKeyMap = computed(() =>
    this.badges().reduce<{ [key: string]: HomePageBadge | undefined }>(
      (badges, b) => {
        badges[b.key] = b;
        return badges;
      },
      {},
    ),
  );

  uploadBadgeImage(file: File): UploadRef {
    return this._uploadService.uploadAsset(file, this.BADGE_IMAGE_FOLDER);
  }

  createBadge(displayName: string, file: string): Observable<void> {
    const cleanBadgeName = displayName
      .replace(/[^A-Z0-9]+/gi, "-")
      .toLowerCase();
    return this._settingsService.createSetting({
      key: `${this.BADGE_KEY_PREFIX}-${cleanBadgeName}`,
      displayName,
      value: extractFilename(file),
    });
  }

  updateBadge(id: number, file: string, displayName: string): Observable<void> {
    return this._settingsService.updateSetting({
      id,
      displayName,
      value: extractFilename(file),
    });
  }

  deleteBadge(id: number): Observable<void> {
    return this._settingsService.deleteSetting(id);
  }
}
