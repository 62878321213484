import { NgClass, NgIf, NgFor } from "@angular/common";
import { Component, OnInit, Input, inject } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from "@angular/common/http";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedService } from "src/app/shared/services/shared.service";
import { DevicesService } from "src/app/core/services/devices.service";
import { AuthService } from "src/app/public/auth/auth.service";
import { EventBrokerService } from "src/app/shared/services/eventBroker.service";
import { environment } from "src/environments/environment";
import { ClientFunctions } from "src/app/shared/interfaces/client-functions";
import { SearchResult } from "src/app/public/interfaces/search-result";
import { AppState } from "src/app/store/state/app.state";
import {
  DisplayStyle,
  ISiteSettings,
} from "src/app/store/models/ISiteSettings";
import { setSiteSettingsDisplayStyleAction } from "src/app/store/actions/SiteSettings.actions";
import { SearchComponent } from "./search/search.component";
import { CategoriesService } from "src/app/services/categories.service";

declare var $: any;

type SelectData = {
  id: number;
  name: string;
  level: string;
  levelId: number;
};

type AllSelectData = {
  id: number;
  name: string;
  placeholder: string;
  isLoading: boolean;
  isDisable: boolean;
  selectData: SelectData[];
  selectedData: any;
};

@Component({
  selector: "fc-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    SearchComponent,
    NgFor,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class NavbarComponent implements OnInit {
  @Input() isFsvSecureAccess?: boolean;

  private _categoriesService = inject(CategoriesService);

  isMobile: boolean = false;
  l1Categories = this._categoriesService.categories;

  searchTerm$ = new Subject<string>();
  searchResults?: SearchResult[];
  showMobileSearchBar: boolean = false;
  clientFunctions: ClientFunctions = {
    mcoe: {},
    fsv: {},
    pbna: {},
  };

  allSelectsData: AllSelectData[] = [];

  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  constructor(
    public sharedService: SharedService,
    private devicesService: DevicesService,
    private http: HttpClient,
    public authService: AuthService,
    private store: Store<AppState>,
    private eventBroker: EventBrokerService,
  ) {
    this.store
      .select((state) => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.displayStyle = s ? s.displayStyle : DisplayStyle.Cards;
        this.isCardLayout = this.displayStyle === DisplayStyle.Cards;
      });
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile();

    this.clientFunctions = {
      mcoe: {
        faqClicked: () => {
          this.sharedService.navigateToPdf("faq");
        },
        contactClicked: () => {
          this.sharedService.navigateToPdf("contacts");
        },
        displayHelpModalClicked: () => {
          $("#help-modal").modal("show");
        },
        displayPrintModalClicked: () => {
          this.displayPrintModal();
        },
        inventoryClicked: () => {
          this.displayInventoryModal();
        },
      },
      fsv: {
        faqClicked: () => {
          this.sharedService.navigateToPdf(
            "21080_067_FSV_Sales_Merchandising_Catalog_FAQ_RB01",
          );
        },
        contactClicked: () => {
          this.sharedService.navigateToPdf(
            "Merchandising_Catalog_Contact_2021",
          );
        },
        displayHelpModalClicked: () => {
          $("#help-modal").modal("show");
        },
        displayPrintModalClicked: () => {
          this.displayPrintModal();
        },
        inventoryClicked: () => {
          this.displayInventoryModal();
        },
      },
      pbna: {
        faqClicked: () => {
          this.sharedService.navigateToPdf("pbna_how_to_order");
        },
        contactClicked: () => {
          this.sharedService.navigateToPdf("pbna_contact");
        },
        displayHelpModalClicked: () => {
          $("#help-modal").modal("show");
        },
        displayPrintModalClicked: () => {
          this.displayPrintModal();
        },
        inventoryClicked: () => {
          this.sharedService.navigateToPdf("pbna_sellsheets");
          // this.adminService.getSettingByKey("pdf-sellSheet-status").pipe(
          //   map(setting => {
          //     if (setting) {
          //       const value = JSON.parse(setting.value);
          //       if (Array.isArray(value.status)) {
          //         const sellsheet = value.status.find((s: any) => s.name === 'Sellsheets');
          //         if (sellsheet && sellsheet.status) {
          //           window.open(sellsheet.status, '_blank');
          //           return;
          //         }
          //       }
          //     }
          //     console.error('Unable to parse pdf-sellSheet-status: ' + setting);
          //   })
          // ).subscribe({
          //   error: e => { console.error('Unable to get PDF setting: ' + e); }
          // });
        },
      },
    };
  }

  displayPrintModal() {
    const categories = this._categoriesService.categories();
    if (this.sharedService.environmentIsFSV()) {
      let allSelectsData: AllSelectData[] = [];

      for (const l1Category of categories) {
        let selectData: SelectData[] = [];
        for (const l2Category of l1Category.children) {
          if (l2Category.children.length > 0) {
            for (const l3Category of l2Category.children) {
              selectData.push({
                id: l3Category.id,
                name: l3Category.name,
                level: l3Category.name,
                levelId: l3Category.id,
              });
            }
          } else {
            selectData.push({
              id: l2Category.id,
              name: "NULL",
              level: l2Category.name,
              levelId: l2Category.id,
            });
          }
        }

        allSelectsData.push({
          id: l1Category.id,
          name: l1Category.name,
          placeholder: l1Category.name + " Categories",
          isLoading: false,
          isDisable: true,
          selectData: selectData,
          selectedData: [],
        });
      }

      this.allSelectsData = allSelectsData;
    }

    $("#print-modal").css("opacity", 0);
    $("#print-modal").modal("show");

    setTimeout(() => {
      $(".print-checkbox:checkbox").attr("disabled", false);
      $(".print-checkbox:checkbox").prop("checked", false);
      $("#print-cancel").attr("disabled", false);
      $("#print-print").attr("disabled", false);
      $("#print-print").text("Print PDF");
      $("#black-overlay").addClass("visible");
      $(".print-spinner").css("opacity", 0);

      $("#print-modal").css("opacity", 1);
    }, 500);
  }

  cancelExportToPDF() {
    $("#black-overlay").removeClass("visible");
    $("#print-modal").modal("hide");
  }

  exportToPDF() {
    var categoriesIds: any[] = [];
    var bugsIds: any[] = [];
    var fullUrl = "";

    if ($("#full-catalog").is(":checked")) {
      fullUrl += "full/";

      $(".print-checkbox.l1-categories:checkbox").each(function (this: any) {
        if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
          if (!$(this).data("titlega").includes("Big Bets")) {
            categoriesIds.push($(this).attr("id"));
          }
        }
      });
    } else {
      fullUrl += "parts/";
      const self = this;
      $(".print-checkbox.l1-categories:checkbox:checked").each(function (
        this: any,
      ) {
        if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
          if (self.sharedService.environmentIsFSV()) {
            var foundSelectData = self.allSelectsData.find(
              (data) => data.id === $(this).attr("id"),
            );

            if (foundSelectData !== undefined) {
              if (foundSelectData.selectedData.length > 0) {
                console.log(foundSelectData);
                foundSelectData.selectedData.forEach((elementId: any) => {
                  categoriesIds.push(elementId);
                });
              } else {
                categoriesIds.push($(this).attr("id"));
              }
            } else {
              categoriesIds.push($(this).attr("id"));
            }
          } else {
            categoriesIds.push($(this).attr("id"));
          }
        }
      });
    }

    $(".print-checkbox.bugs-categories:checkbox:checked").each(function (
      this: any,
    ) {
      if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
        bugsIds.push($(this).attr("id"));
      }
    });

    if (categoriesIds.length > 0) {
      fullUrl += categoriesIds.join("&");

      if (bugsIds.length > 0) {
        let joinedBugsIds = bugsIds.join("&");

        fullUrl += "/" + joinedBugsIds;
      } else {
        fullUrl += "/null";
      }
    } else {
      if (bugsIds.length > 0) {
        let joinedBugsIds = bugsIds.join("&");

        fullUrl += "null/" + joinedBugsIds;
      }
    }

    if (categoriesIds.length > 0 || bugsIds.length > 0) {
      var pdfName = "";

      if (this.sharedService.environmentIsMCOE()) {
        pdfName = "MCOECatalogExport-" + Date.now() + ".pdf";
      } else if (this.sharedService.environmentIsFSV()) {
        pdfName = "PepsiCoFoodserviceCatalogExport-" + Date.now() + ".pdf";
      } else if (this.sharedService.environmentIsPBNA()) {
        pdfName = "PBNACatalogExport-" + Date.now() + ".pdf";
      }

      this.generatePDF(fullUrl, pdfName);
    }
  }

  private generatePDF(fullUrl: string, pdfName: string) {
    $(".print-checkbox:checkbox").attr("disabled", true);
    $("#print-cancel").attr("disabled", true);
    $("#print-print").attr("disabled", true);
    $(".print-spinner").css("opacity", 1);
    $("#print-print").text("Generating PDF...");

    let data = {
      printUrl: environment.frontEnd + "print/" + fullUrl,
      pdfName: pdfName,
    };

    this.http
      .post(environment.endPoint + "/pdf/generate", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
        reportProgress: false,
        observe: "events",
      })
      .subscribe(
        (events) => {
          if (events.type === HttpEventType.Response) {
            if (events.status === 200) {
              if (
                (events.body as any)["pdfUrl"] !== undefined &&
                (events.body as any)["pdfUrl"] !== null
              ) {
                window.open((events.body as any)["pdfUrl"]);
              }

              $("#black-overlay").removeClass("visible");
              $("#print-modal").modal("hide");
            } else {
              console.log(
                "Error during generate pdf :",
                events.status,
                events.body,
              );
              $("#black-overlay").removeClass("visible");
              $("#print-modal").modal("hide");
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log("Error during generate pdf :", error);
          $("#black-overlay").removeClass("visible");
          $("#print-modal").modal("hide");
        },
      );
  }

  toggleShowMobileSearchBar() {
    this.showMobileSearchBar = !this.showMobileSearchBar;
  }

  cancelSearchClicked() {
    this.toggleShowMobileSearchBar();
  }

  closeHelpModal() {
    $("#help-modal").modal("hide");
  }

  toggleCheckbox(elementId: any) {
    $("#" + elementId).toggleClass("selected");

    if (this.sharedService.environmentIsFSV()) {
      if (elementId === "full-catalog") {
        this.allSelectsData.forEach((selectData) => {
          var toCheck = $("#" + elementId).is(":checked") ? true : false;
          $("#" + selectData.id).prop("checked", toCheck);
          selectData.placeholder = $("#" + elementId).is(":checked")
            ? "All Categories Selected"
            : selectData.name + " Categories";
          selectData.isDisable = $("#" + elementId).is(":checked")
            ? false
            : true;
        });
      }

      var foundSelectData = this.allSelectsData.find(
        (data) => data.id === elementId,
      );

      if (foundSelectData !== undefined) {
        $("#full-catalog").prop("checked", false);

        foundSelectData.isDisable = !foundSelectData.isDisable;

        if (foundSelectData.isDisable) {
          foundSelectData.placeholder = foundSelectData.name + " Categories";
          foundSelectData.selectedData = [];
        } else {
          foundSelectData.placeholder = "All Categories Selected";
        }
      }
    }
  }

  onSelectOpen(selectData: AllSelectData) {
    selectData.isLoading = true;

    setTimeout(() => {
      $("span")
        .filter(function (this: any) {
          return $(this).text() == "NULL";
        })
        .parent()
        .remove();
    });

    setTimeout(() => {
      $(".ng-dropdown-panel ").css("display", "block");
      selectData.isLoading = false;
    }, 500);
  }

  groupValueFn = (id: string, children: any[]) => ({ id: children[0].levelId });

  displayInventoryModal() {
    $("#black-overlay").addClass("visible");
    $("#inventory-modal").modal("show");
  }

  switchDisplayStyle() {
    this.store.dispatch(
      setSiteSettingsDisplayStyleAction({
        displayStyle:
          this.displayStyle === DisplayStyle.Cards
            ? DisplayStyle.Rows
            : DisplayStyle.Cards,
      }),
    );

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>("productsLoaded", undefined);
    }, 500);
  }

  goToHome(): void {
    this.sharedService.navigateToUrl("/");
  }
}
