<div
  class="row catalog pb-4"
  [ngClass]="{
    'pt-0': sharedService.environmentIsFSV(),
    'pt-4': !sharedService.environmentIsFSV(),
  }"
>
  <div
    class="col-12 col-lg-6 left"
    [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
  >
    <div class="btn-wrap">
      @if (bannerImages()["asset-homepage-top-left"]?.image) {
        <img [src]="bannerImages()['asset-homepage-top-left']?.image" />
      }
      <div
        class="catalog-button"
        [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
      >
        @if (bannerLinks()["action-homepage-top-left"]?.url) {
          <button
            class="btn"
            (click)="
              navigateToUrl(
                bannerLinks()['action-homepage-top-left']?.url,
                $event
              )
            "
          >
            @if (bannerImages()["asset-homepage-button-top-left"]?.image) {
              <img
                [src]="bannerImages()['asset-homepage-button-top-left']?.image"
                class="ga-clic-homepage"
                data-titlega="Browse The Catalog"
              />
            }
          </button>
        }
      </div>
    </div>
  </div>

  <div
    class="col-12 col-lg-6 right"
    [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
  >
    <div class="row top" [ngClass]="{ fsv: sharedService.environmentIsFSV() }">
      @if (sharedService.environmentIsPBNA()) {
        <div class="col-6 h-100 top">
          <div class="btn-wrap">
            @if (bannerImages()["asset-homepage-top-right-1"]?.image) {
              <img
                [src]="bannerImages()['asset-homepage-top-right-1']?.image"
              />
            }
            <div class="top-button">
              @if (bannerLinks()["action-homepage-button-top-right-1"]?.url) {
                <button
                  class="btn"
                  (click)="
                    navigateToUrl(
                      bannerLinks()['action-homepage-button-top-right-1']?.url,
                      $event
                    )
                  "
                >
                  @if (
                    bannerImages()["asset-homepage-button-top-right-1"]?.image
                  ) {
                    <img
                      [src]="
                        bannerImages()['asset-homepage-button-top-right-1']
                          ?.image
                      "
                      class="ga-clic-homepage"
                      data-titlega="View All Special Offers"
                    />
                  }
                </button>
              }
            </div>
          </div>
        </div>
        <div class="col-6 h-100 top">
          <div class="btn-wrap">
            @if (bannerImages()["asset-homepage-top-right-2"]?.image) {
              <img
                [src]="bannerImages()['asset-homepage-top-right-2']?.image"
              />
            }
            <div class="top-button">
              @if (bannerLinks()["action-homepage-button-top-right-2"]?.url) {
                <button
                  class="btn"
                  (click)="
                    navigateToUrl(
                      bannerLinks()['action-homepage-button-top-right-2']?.url,
                      $event
                    )
                  "
                >
                  @if (
                    bannerImages()["asset-homepage-button-top-right-2"]?.image
                  ) {
                    <img
                      [src]="
                        bannerImages()['asset-homepage-button-top-right-2']
                          ?.image
                      "
                      class="ga-clic-homepage"
                      data-titlega="View All Special Offers"
                    />
                  }
                </button>
              }
            </div>
          </div>
        </div>
      } @else {
        <div class="col-12 h-100 top">
          <div class="btn-wrap">
            @if (bannerImages()["asset-homepage-top-right"]?.image) {
              <img [src]="bannerImages()['asset-homepage-top-right']?.image" />
            }
            <div
              class="red-hot-button"
              [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
            >
              @if (bannerLinks()["action-homepage-top-right"]?.url) {
                <button
                  class="btn"
                  (click)="
                    navigateToUrl(
                      bannerLinks()['action-homepage-top-right']?.url,
                      $event
                    )
                  "
                >
                  @if (
                    bannerImages()["asset-homepage-button-top-right"]?.image
                  ) {
                    <img
                      [src]="
                        bannerImages()['asset-homepage-button-top-right']?.image
                      "
                      class="ga-clic-homepage"
                      data-titlega="View All Special Offers"
                    />
                  }
                </button>
              }
            </div>
          </div>
        </div>
      }
    </div>

    @if (!sharedService.environmentIsFSV()) {
      <div class="row middle">
        <div class="col-12" h-100></div>
      </div>
    }

    <div
      class="row bottom"
      [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
    >
      <div class="col-12 h-100">
        @if (
          bannerLinks()["action-homepage-bottom-right"]?.url ||
          bannerImages()["asset-homepage-bottom-right"]?.image
        ) {
          <button
            class="btn btn-content"
            [disabled]="!bannerLinks()['action-homepage-bottom-right']?.url"
            (click)="
              navigateToUrl(
                bannerLinks()['action-homepage-bottom-right']?.url,
                $event
              )
            "
          >
            @if (bannerImages()["asset-homepage-bottom-right"]?.image) {
              <img
                [src]="bannerImages()['asset-homepage-bottom-right']?.image"
                class="bottom ga-clic-homepage"
                data-titlega="New A.C.E Designs"
              />
            }
            <div
              class="ace-button"
              [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
            >
              @if (bannerLinks()["action-homepage-button-bottom-right"]?.url) {
                <button
                  class="btn"
                  (click)="
                    navigateToUrl(
                      bannerLinks()['action-homepage-button-bottom-right']?.url,
                      $event
                    )
                  "
                >
                  @if (
                    bannerImages()["asset-homepage-button-bottom-right"]?.image
                  ) {
                    <img
                      [src]="
                        bannerImages()['asset-homepage-button-bottom-right']
                          ?.image
                      "
                      class="ga-clic-homepage"
                      data-titlega="Check Out The Latest Innovation Here"
                    />
                  }
                </button>
              }
            </div>
          </button>
        }
      </div>
    </div>
  </div>
</div>

@for (section of sections(); track section.id) {
  <div class="row pt-3 pb-3 homepage-sections">
    <div class="col-12 title-text">
      <div class="title">
        {{ section.name }}
      </div>
    </div>
    <div class="col-12">
      <div class="separator mb-4"></div>
    </div>
    @if (!isMobile) {
      <div
        class="row ml-0 mr-0 content w-100 flex-center-center"
        [ngClass]="{
          'custom-background': section.showBackground === true,
        }"
      >
        @for (item of section.items; track item.id) {
          <div class="col-17 col-img mt-3 mb-3">
            <button class="btn" (click)="navigateToUrl(item.targetUrl, $event)">
              <div class="image-with-badge">
                <img
                  class="ga-clic-homepage"
                  [attr.data-titlega]="section.name + ' - ' + item.name"
                  [src]="item.imageUrl"
                />
                @if (
                  item.badgeKey &&
                  item.badgeKey !== "null" &&
                  badgesMap()[item.badgeKey]?.image
                ) {
                  <img
                    [src]="badgesMap()[item.badgeKey]?.image"
                    class="image-badge"
                    [ngClass]="{
                      'image-with-shadow':
                        section.name === 'Popular Categories',
                    }"
                  />
                }
              </div>
            </button>
          </div>
        }
      </div>
    }

    @if (isMobile) {
      <div class="row ml-0 mr-0 w-100">
        @for (item of section.items; track item.id) {
          <div class="col-12 col-link">
            <div>
              <button
                class="btn ga-clic-homepage"
                (click)="navigateToUrl(item.targetUrl, $event)"
                [attr.data-titlega]="section.name + ' - ' + item.name"
              >
                <span>{{ item.name }}</span>
                <i class="material-icons">chevron_right</i>
              </button>
            </div>
          </div>
        }
      </div>
    }
  </div>
}

<div
  class="modal fade"
  id="ie-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ieModal"
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ieModal">Internet Explorer detected</h5>
      </div>
      <div class="modal-body">
        <p class="description mb-0">
          Please note that this site is optimized for the Google Chrome web
          browser.
          <br />
          Users of older browsers such as Internet Explorer 11 may notice
          performance issues.
          <br /><br />
          For an optimal browsing experience, please load this site on Google
          Chrome.
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="help-close"
          class="btn btn-1"
          (click)="closeIeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
