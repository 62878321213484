<div class="container row justify-content-center">
  <div class="col-12 col-lg-6">
      <form class="needs-validation" novalidate>
          <img class="logo" src="assets/images/login/Update_Login_Image.png">
          <div class="form-group">
              <input type="password" class="form-control" id="password" name="password" placeholder="Enter password"
                  [(ngModel)]="password" required>
              <div class="invalid-feedback">
                  Please provide a password.
              </div>
          </div>
          <div class="form-group">
              <small class="text-danger">{{message}}</small>
          </div>
          <button type="button" class="btn btn-1" (click)="validateForm()">Login</button>
      </form>
  </div>
</div>