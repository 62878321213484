import { Component, Input } from "@angular/core";
import { Product } from "src/app/types/product.type";

@Component({
  selector: "fc-sellsheet-template-pbna",
  templateUrl: "./sellsheet-template-pbna.component.html",
  styleUrls: ["./sellsheet-template-pbna.component.scss"],
  standalone: true,
  imports: [],
})
export class SellsheetTemplatePBNAComponent {
  @Input({ required: true }) product!: Product;
  @Input() page: number = 0;

  image: string = "assets/images/common/no_image.svg";
  rationale: string[] = [];
  suggestedLocations: string[] = [];
  packout: string[] = [];
  specifications: string[] = [];

  ngOnInit() {
    this.rationale =
      this.product.sellsheet?.displaySpec.rationale?.split("\n") ?? [];
    this.suggestedLocations =
      this.product.sellsheet?.displaySpec.suggestedLocations?.split("\n") ?? [];
    this.packout =
      this.product.sellsheet?.displaySpec.packout?.split("\n") ?? [];
    this.specifications =
      this.product.sellsheet?.displaySpec.specifications?.split("\n") ?? [];

    if (this.product.images.length > 0) {
      this.image = this.product.images[0].largeThumb;
    }
  }
}
