import { Environment } from "../environment.type";

export const environment: Environment = {
  production: true,
  environmentName: 'MCOE - Prod',
  environmentId: 'mcoe',
  container: 'https://fritolay.blob.core.windows.net',
  endPoint: 'https://mcoe-backend.azurewebsites.net',
  graphqlEndPoint: 'https://mcoe-backend.azurewebsites.net/graphql',
  frontEnd: 'https://mcoe-frontend.azurewebsites.net/',
  analytics: 'UA-156002692-1 - GTM-MS8PSDN',
  adminUsername: 'frito',
  adminPassword: 'lay',
};
