import { Action, createReducer, on } from '@ngrx/store';
import { setSiteSettingsBugsAction, setSiteSettingsDisplayStyleAction } from '../actions/SiteSettings.actions';
import { DisplayStyle, ISiteSettings } from '../models/ISiteSettings';


export const setSiteSettingsReducer = createReducer<ISiteSettings, Action>(
  { isBugs: false, displayStyle: DisplayStyle.Cards },
  on(setSiteSettingsBugsAction, (state, payload) => ({ ...state, isBugs: payload.isBugs })),
  on(setSiteSettingsDisplayStyleAction, (state, payload) => ({ ...state, displayStyle: payload.displayStyle })),
);
